<template>
  <el-dialog
    class="script-broll-material-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="dialog-container">
      <div class="header-wrapper">
        <div class="header-container">
          <span 
          >
            Choose a voice
          </span>
          <icon-button
            name="icon_close"
            color="#646A73"
            :size="20"
            @click="handleClose"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="scroll-wrapper">
          <el-scrollbar class="scroll-container">
            <el-skeleton animated :loading="loading && page === 0">
              <template #template>
                <el-skeleton-item v-for="(_, i) in Array(40)" variant="rect" :key="i" />
              </template>
              <div 
                class="voice-list"
                v-infinite-scroll="loadMore"
                :infinite-scroll-distance="50"
                :infinite-scroll-immediate="false"
                :style="{ paddingBottom: loadAll? '180px' : '0'}"
              >
                <div 
                  v-for="item in voiceList"
                  :key="item.name"
                  class="voice-item"
                  :class="{active: voice == item}"
                  @click="voiceClick(item)"
                >
                  <div class="left">
                    <div class="voice-cover-container">
                      <img
                        alt=""
                        :src="icon_playing"
                        class="icon"
                        @click.stop="pause"
                        v-if="currentAudio.url === item.url && currentAudio.playing"
                      />
                      <img 
                        alt=""
                        :src="icon_play_video"
                        class="icon"
                        @click.stop="playClick(item)"
                        v-else
                      />
                      <img 
                        :src="item.coverPic" 
                        :alt="item.name" 
                        class="voice-cover"
                      >
                    </div>
                    <div class="voice-detail">
                      <bv-tip
                        :content="item.name"
                        placement="top"
                      >
                        <p class="voice-name">{{ item.name }}</p>
                      </bv-tip>
                      <div class="voice-control" v-if="currentAudio.url === item.url">
                        <p>
                          {{ `${dayjs.duration(currentAudio.currentTime, "seconds").format("mm:ss")} / ${dayjs.duration(currentAudio.audio.duration, "seconds").format("mm:ss")}` }}
                        </p>
                        <el-slider
                          @click.stop
                          v-model="currentAudio.currentTime"
                          @change="seekTo"
                          :show-tooltip="false"
                          :step="0.1"
                          :min="0"
                          :max="currentAudio.audio.duration"
                        />
                      </div>
                    </div>

                  </div>
                  <div class="right">
                    <img
                      :src="icon_selected"
                      alt=""
                      v-if="voice == item"
                    />
                    <img
                      :src="icon_select"
                      alt=""
                      v-else
                    />
                  </div>
                </div>
              </div>
            </el-skeleton>
            <Loading v-show="!loadAll && loading && page > 1"/>
          </el-scrollbar>
        </div>
      </div>
      <div class="footer-wrapper">
        <primary-button 
          size="small"
          :disabled="!voice" 
          @click="replaceClick"
        >
          <span style="width: 108px">
            Replace
          </span>
        </primary-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import dayjs from 'dayjs';
import Loading from './loading.vue';
import { getSimilarVoiceList } from '@/api/similarVideo';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_playing from '@/assets/similar-video/icon_playing.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import icon_play_video from '@/assets/similar-video/icon_play_video.svg';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const emits = defineEmits([
  "update:modelValue",
  "confirm"
]);
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
});

const size = 20;
const page = ref(0);
const loading = ref(true);
const loadAll = ref(false);
const voice = ref(null);
const voiceList = ref([]);
const currentAudio = reactive({
  url: null,
  audio: null,
  playing: false,
  loading: true,
  currentTime: 0,
});

const play = (url) => {
  if (currentAudio.url !== url) {
    destroyAudio();
    const audio = new Audio(url);
    audio.ontimeupdate = () => (currentAudio.currentTime = audio.currentTime);
    audio.oncanplay = () => { 
      currentAudio.loading = false;
    };
    audio.onended = () => {
      currentAudio.playing = false;
      audio.currentTime = 0;
    };

    currentAudio.url = url;
    currentAudio.audio = audio;
  }

  currentAudio.playing = true;
  currentAudio.audio.play();
};

const pause = () => {
  if (!currentAudio.audio) return;
  currentAudio.playing = false;
  currentAudio.audio.pause();
};

const seekTo = (value) => {
  currentAudio.audio.currentTime = value;
};

const destroyAudio = () => {
  if (currentAudio.audio) {
    currentAudio.url = null;
    currentAudio.playing = false;
    currentAudio.audio.ontimeupdate = null;
    currentAudio.audio.onended = null;
    currentAudio.audio.pause();
    currentAudio.audio = null;
    currentAudio.currentTime = 0;
    currentAudio.loading = true;
  }
}

const handleClose = () => {
  emits("update:modelValue", false);
  voice.value = null;
  destroyAudio();
};

const voiceClick = (value) => {
  destroyAudio();
  if (voice.value === value) {
    voice.value = null;
  }
  else {
    voice.value = value;
  }
};

const playClick = (value) => {
  if (voice.value != value) {
    voice.value = value; 
  }
  play(value.url);
};

const replaceClick = () => {
  emits("confirm", voice.value);
  console.log(voice.value);
  handleClose();
};

const loadMore = async () => {
  if (loadAll.value) return;

  loading.value = true;
  const params = {
    size: size,
    page: page.value + 1,
  };
  
  const {code, data} = await getSimilarVoiceList(params);
  if (code === 0) {
    if (data.records.length > 0) {
      voiceList.value = [...voiceList.value, ...data.records];
      page.value ++;
    }
    else if (data.records.length === 0) {
      loadAll.value = true;
    }
  }
  loading.value = false;
  console.log(voiceList.value);
}

const setup = async () => {
  await loadMore();
};

onBeforeMount(setup);
</script>

<style>
.script-broll-material-dialog {
  width: 65%;
  height: 80%;
  min-width: 840px;
  min-height: 500px;
  border-radius: 4px;
}

.script-broll-material-dialog .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;
}
.script-broll-material-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
</style>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & :deep(.bv-tooltip-content-container) {
    width: fit-content; 
    height: fit-content;
  }
}
.header-wrapper {
  flex: 0 0;
  padding: 20px 46px 24px;;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

.content-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.footer-wrapper {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;

  & .stepback {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.scroll-wrapper {
  height: 100%;
  padding: 0 5px;
}

.scroll-container {
  padding: 0 24px 0;
}

.voice-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(376px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
}

.voice-item {
  padding: 12px 17px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
  cursor: pointer;

  &.active,
  &:hover {
    background: #F8F5FF;
  }

  & > .left {
    display: flex;

  }

  & .voice-cover-container {
    position: relative;

    & > .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%); 
    }
  }

  & .voice-cover {
    border-radius: 4px;
    width: 100px;
    height: 100px;
    margin-right: 12px;
  }

  & .voice-name {
    max-width: 124px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  & .voice-detail {
    height: 100%;
  }

  & .voice-control {
    margin-top: auto;
  }

  & :deep(.el-slider) {
    width: 187px;
  }

  & :deep(.el-slider__runway) {
    height: 4px;
    background: #e5e7eb;
  }

  & :deep(.el-slider__bar) {
    height: 4px;
  }

  & :deep(.el-slider__button) {
    border: 1px solid #e5e7eb;
    transition: none;
    width: 14px;
    height: 14px;
    box-shadow: 2px 2px 4px #eae8e8;
    transform: none;
    &:hover {
      width: 14px;
      height: 14px;
      transform: none;
    }
  }

  & :deep(.el-slider__button-wrapper) {
    top: -16px;
    &:hover {
      :deep(.el-slider__button) {
        transform: none;
      }
    }
  }
}
</style>