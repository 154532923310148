<template>
  <Layout></Layout>
</template>

<script setup>
import Layout from "./components/layout/index.vue"; 
import { useTrackStore } from "@/store/modules/track";

const route = useRoute();
const { collectData } = useTrackStore();
document.title = route.meta.title;
collectData("boolvideo_editor_page_view", {
  editor_type: "scene_editor",
});
</script>

<style lang="scss" scoped>

</style>