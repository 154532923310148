<script setup>
import { useNetwork } from "@/composables";
import { getCloudMaterialList } from "@/api/material";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import { secondsToHms } from "../../utils";
import Empty from "./empty.vue";
import Loading from "./loading.vue";

const filterOptions = [
  {
    label: "Type",
    value: "type",
    items: [
      {
        label: "Image",
        value: "image",
      },
      {
        label: "Video",
        value: "video",
      },
      {
        label: "Audio",
        value: "audio",
      },
    ],
  },
  {
    label: "Source",
    value: "source",
    items: [
      {
        label: "Original",
        value: "original",
      },
      {
        label: "AI generated",
        value: "ai",
      },
    ],
  },
];

const {
  getXY,
  mediaTab,
  updateProject,
  updateCloud,
  addNode,
  secondToFrame,
} = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();
const { assertNetworkError } = useNetwork();

const materialWidth = inject("materialWidth");
const uploader = ref(null);
const list = ref(null);
const files = ref([]);
const page = ref(1);
const total = ref(0);
const loading = ref(false);
const empty = computed(() => files.value.length === 0);
const disabled = computed(
  () => loading.value || files.value.length >= total.value
);
const filters = reactive({ type: new Set(), source: new Set() });
const active = computed(() => filters.type.size > 0 || filters.source.size > 0);

watch(
  [materialWidth, files],
  () => {
    if (
      !list.value ||
      list.value.scrollHeight < list.value.parentElement.clientHeight
    ) {
      getCloudMaterial();
    }
  },
  { immediate: true }
);
watch(updateProject, () => (mediaTab.value = "project"));
watch(updateCloud, () => getCloudMaterial(true));

async function getCloudMaterial(order) {
  if (loading.value) {
    return;
  }
  loading.value = true;
  const params = {
    page: order ? 1 : page.value,
    size: order ? 1 : 20,
  };
  if (filters.type.size > 0) {
    params.filterTypes = Array.from(filters.type);
  }
  if (filters.source.size > 0) {
    params.source = Array.from(filters.source);
  }
  const response = await getCloudMaterialList(params);

  if (response.success) {
    const records = response.data.records;
    total.value = response.data.total;

    if (order) {
      files.value = records.concat(files.value);
    } else {
      files.value = files.value.concat(records);
      page.value++;
    }
    for (const file of files.value) {
      if (file.state > 1) {
        uploader.value
          ?.getUploadState(file)
          .then((info) => Object.assign(file, info))
          .catch((e) => Object.assign(file, e));
      }
    }
  }
  loading.value = false;
}

async function filter() {
  files.value = [];
  page.value = 1;
  await getCloudMaterial();
}

async function addAsset(eventName, e, file, target) {
  assertNetworkError();
  const hdUrl = file.preview1080Url;
  const materialMeta = {
    width480: file.width480,
    width1080: file.width1080,
    url1080: hdUrl,
  };
  const conf = {
    type: file.type,
    src: file.previewUrl,
    name: file.name,
  };
  switch (file.type) {
    case "image":
      Object.assign(conf, {
        ...getXY(),
        hdUrl,
        materialMeta,
        fit: "contain",
        duration: 90,
      });
      break;
    case "video":
      Object.assign(conf, {
        ...getXY(),
        hdUrl,
        materialMeta,
        coverPic: file.coverPic,
        fit: "contain",
        duration: secondToFrame(file.duration),
        transparent: file.aiType === "videoBgRemove",
      });
      break;
    case "audio":
      Object.assign(conf, { duration: secondToFrame(file.duration) });
      break;
    case "effect":
      Object.assign(conf, { loop: true });
      break;
  }
  if (eventName === "mousemove") {
    Object.assign(dragData, {
      x: e.clientX,
      y: e.clientY,
      target,
      meta: conf,
    });
  } else {
    await addNode(conf, { shouldRefresh: true, shouldAnnotate: true });
    commit();
    updateDraft();
  }
}
</script>
<template>
  <bv-uploader v-model="files" ref="uploader" />
  <div class="header">
    <group-select
      v-model="filters"
      canReset
      :teleported="false"
      :width="230"
      :options="filterOptions"
      @change="filter"
    >
      <icon-button
        border
        name="editor_library_filter"
        :size="18"
        :active="active"
        :color="active ? '#FFFFFF' : '#646A73'"
      />
    </group-select>
  </div>
  <el-scrollbar ref="scrollbar">
    <el-skeleton animated :loading="loading && page === 1">
      <template #template>
        <el-skeleton-item v-for="(_, i) in Array(20)" variant="rect" :key="i" />
      </template>
      <empty v-if="empty" />
      <div
        v-else
        ref="list"
        class="file-list"
        v-infinite-scroll="getCloudMaterial"
        :infinite-scroll-immediate="false"
        :infinite-scroll-disabled="disabled"
      >
        <div class="file-item" v-for="file in files" :key="file.mid">
          <div class="file-content">
            <div
              v-drag:[file]="addAsset"
              class="image-wrapper"
            >
              <div class="image-mask" v-if="file.state > 1">
                <span v-if="file.tag !== 'ai'">Uploading</span>
                <el-progress
                  :percentage="file.progress"
                  :show-text="false"
                  :color="file.tag === 'ai' ? '#FFE39B' : '#FFFFFF'"
                />
              </div>
              <img
                v-if="file.coverPic"
                :src="file.coverPic"
                loading="lazy"
                draggable="false"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'image'"
                name="editor_thumb_image"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'video'"
                name="editor_thumb_video"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-else-if="!file.coverPic && file.type === 'audio'"
                name="editor_thumb_music"
                color="#95979A"
                :size="24"
              />
              <svg-icon
                v-if="file.tag === 'ai'"
                class="ai-tag"
                name="editor_ai_tools"
                :size="16"
              />
            </div>
            <span
              v-if="['video', 'audio'].includes(file.type) && file.duration"
              class="duration"
              >{{ secondsToHms(file.duration) }}</span
            >
          </div>
          <div class="title">{{ file.name }}</div>
        </div>
      </div>
      <loading v-show="loading && page > 1" />
    </el-skeleton>
  </el-scrollbar>
</template>
<style scoped>
.el-scrollbar {
  height: calc(100% - 60px);
}
.header {
  width: 100%;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.file-list,
:deep(.el-skeleton) {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fill, minmax(74px, 1fr));
  padding: 0 16px;
}

.file-content {
  margin-bottom: 4px;
  overflow: hidden;
  position: relative;
}

.image-wrapper,
:deep(.el-skeleton__item) {
  width: 100%;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f3f5f7;
  border-radius: 4px;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}

.ai-tag {
  position: absolute;
  left: 8px;
  bottom: 10px;
}

.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
.image-wrapper .image-mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
}

.image-wrapper .image-mask span {
  color: #f8f5ff;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 8px;
}
.file-content .duration {
  padding: 0 8px;
  position: absolute;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.45);
  right: 8px;
  bottom: 8px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  text-overflow: ellipsis;
  overflow: hidden;
  user-select: none;
  white-space: nowrap;
}
:deep(.icon-button.border) {
  border-radius: 4px;
  border: 1px solid #d5d6d7;
  margin-left: 12px;
}
:deep(.icon-button.border .icon-wapper) {
  padding: 8px;
}
:deep(.icon-button.active) {
  border: 1px solid #fff;
  background-color: #875eff;
}
</style>
