<template>
  <el-dialog
    class="script-hook-material-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="dialog-container">
      <div class="header-wrapper">
        <div class="header-container">
          <span 
            v-if="!celebrity"
          >
            {{ dialogTitle }}
          </span>
          <span 
            class="stepback"
            @click="stepback"
            v-else
          >
            <svg-icon
              name="icon_arrow_left_brandkit"
              color="#1C1B1E"
              :size="24"
            />
            Back
          </span>
          <icon-button
            name="icon_close"
            color="#646A73"
            :size="20"
            @click="handleClose"
          />
        </div>
      </div>
      <div class="content-wrapper">
        <div class="scroll-wrapper">
          <el-scrollbar class="scroll-container" v-if="!celebrity">
            <el-skeleton animated :loading="loading && page === 0">
              <template #template>
                <el-skeleton-item v-for="(_, i) in Array(40)" variant="rect" :key="i" />
              </template>
              <div 
                class="celebrity-list"
                v-infinite-scroll="loadMore"
                :infinite-scroll-distance="50"
                :infinite-scroll-immediate="false"
                :style="{ paddingBottom: loadAll? '180px' : '0'}"
              >
                <div 
                  v-for="item in celebrityList"
                  :key="item.name"
                  class="celebrity-item"
                  @click="celebrityClick(item)"
                >
                  <div class="left">
                    <img 
                      :src="item.coverPic" 
                      :alt="item.name" 
                      class="celebrity-cover"
                    >
                  </div>
                  <div class="right">
                    <bv-tip
                      :content="item.name"
                      placement="top"
                    >
                      <p class="celebrity-name">{{ item.name }}</p>
                    </bv-tip>
                    <p class="celebrity-size">{{ `${item.size} video` }}</p>
                  </div>
                </div>
              </div>
            </el-skeleton>
            <Loading v-show="!loadAll && loading && page > 1"/>
          </el-scrollbar>
          <el-scrollbar class="scroll-container" v-else>
            <div class="hook-list">
              <div 
                v-for="media in celebrity.medias"
                class="hook-item"
                :class="{ active: selectedHook == media}"
                @click="hookClick(media)"
              >
                <div class="hook-detail">
                  <div class="left">
                    <div 
                      class="hook-cover-box"
                      @click.stop="playClick(media)"
                    >
                      <img
                        alt=""
                        :src="icon_play_video"
                        class="icon-play"
                        v-show="currentVideo.url !== media.url || !currentVideo.playing"
                      />
                      <video 
                        :src="media.url" 
                        :id="`celebrity_video_${media.url}`"
                        :muted="scene.type === 'voice_sticker'"
                        v-show="currentVideo.url == media.url"
                      />
                      <img class="cover" :src="media.coverPic" :alt="media.name" v-show="currentVideo.url !== media.url">
                    </div>
                  </div>
                  <div class="right">
                    <p class="hook-name">
                      {{ media.name }}
                    </p>
                    <div class="hook-control" v-if="currentVideo.url === media.url">
                      <p>
                        {{ `${dayjs.duration(currentVideo.currentTime, "seconds").format("mm:ss")} / ${dayjs.duration(currentVideo.video.duration, "seconds").format("mm:ss")}` }}
                      </p>
                      <el-slider
                        @click.stop
                        v-model="currentVideo.currentTime"
                        @change="seekTo"
                        :show-tooltip="false"
                        :step="0.1"
                        :min="0"
                        :max="currentVideo.video.duration"
                      />
                    </div>
                    <p class="hook-duration" v-else>
                      {{ dayjs.duration(media.duration, "seconds").format("mm:ss") }}
                    </p>
                  </div>
                </div>
                <div>
                  <img
                    :src="icon_selected"
                    alt=""
                    v-if="selectedHook == media"
                  />
                  <img
                    :src="icon_select"
                    alt=""
                    v-else
                  />
                </div>
              </div>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="footer-wrapper">
        <primary-button 
          size="small"
          :disabled="!selectedHook" 
          @click="replaceClick"
        >
          <span style="width: 108px">
            Replace
          </span>
        </primary-button>
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import dayjs from 'dayjs';
import Loading from './loading.vue';
import { getSimilarHookList, getSimilarStickersList } from '@/api/similarVideo';
import icon_select from '@/assets/similar-video/icon_select.svg';
import icon_selected from '@/assets/similar-video/icon_selected.svg';
import icon_play_video from '@/assets/similar-video/icon_play_video.svg';
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

const emits = defineEmits([
  "update:modelValue",
  "confirm"
]);
const props = defineProps({
  modelValue: {
    type: Boolean,
  },
  scene: {
    type: Object,
  }
});

const size = 20;
const page = ref(0);
const loading = ref(true);
const loadAll = ref(false);
const celebrity = ref(null);
const celebrityList = ref([]);
const selectedHook = ref(null);
const currentVideo = reactive({
  url: null,
  video: null,
  playing: false,
  loading: true,
  currentTime: 0,
});
const dialogTitle = computed(() => {
  switch(props.scene.type) {
      case "hook_start":
        return "Choose a celebrity hook";
      case "voice_sticker":
        return "Choose a celebrity sticker";
    }
});

const play = (url) => {
  if (currentVideo.url !== url) {
    destroyVideo();
    const video = document.getElementById(`celebrity_video_${url}`);
    video.ontimeupdate = () => (currentVideo.currentTime = video.currentTime);
    video.oncanplay = () => { 
      currentVideo.loading = false;
    };
    video.onended = () => {
      currentVideo.playing = false;
      video.currentTime = 0;
    };

    currentVideo.url = url;
    currentVideo.video = video;
  }

  currentVideo.playing = true;
  currentVideo.video.play();
};

const pause = () => {
  if (!currentVideo.video) return;
  currentVideo.playing = false;
  currentVideo.video.pause();
};

const seekTo = (value) => {
  currentVideo.video.currentTime = value;
};

const destroyVideo = () => {
  if (currentVideo.video) {
    currentVideo.url = null;
    currentVideo.playing = false;
    currentVideo.video.ontimeupdate = null;
    currentVideo.video.onended = null;
    currentVideo.video.pause();
    currentVideo.video = null;
    currentVideo.currentTime = 0;
    currentVideo.loading = true;
  }
}

const handleClose = () => {
  emits("update:modelValue", false);
  celebrity.value = null;
  selectedHook.value = null;
  destroyVideo();
};

const stepback = () => {
  celebrity.value = null;
  selectedHook.value = null;
};

const celebrityClick = (value) => {
  celebrity.value = value
};

const hookClick = (value) => {
  destroyVideo();
  if (selectedHook.value == value) {
    selectedHook.value = null;
  }
  else {
    selectedHook.value = value; 
  }
};

const playClick = (value) => {
  if (selectedHook.value != value) {
    selectedHook.value = value; 
  }
  play(value.url);
};

const replaceClick = () => {
  emits("confirm", selectedHook.value);
  handleClose();
};

const loadMore = async () => {
  if (loadAll.value) return;

  loading.value = true;
  const params = {
    size: size,
    page: page.value + 1,
  };
  
  const getList = async (params) => {
    switch(props.scene.type) {
      case "hook_start":
        return await getSimilarHookList(params);
      case "voice_sticker":
        return await getSimilarStickersList(params);
    }
  };
  const {code, data} = await getList(params);
  if (code === 0) {
    if (data.records.length > 0) {
      celebrityList.value = [...celebrityList.value, ...data.records];
      page.value ++;
    }
    else if (data.records.length === 0) {
      loadAll.value = true;
    }
  }
  loading.value = false;
}

const setup = async () => {
  await loadMore();
};

onBeforeMount(setup);
</script>

<style>
.script-hook-material-dialog {
  width: 65%;
  height: 80%;
  min-width: 840px;
  min-height: 500px;
  border-radius: 4px;
}

.script-hook-material-dialog .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;
}
.script-hook-material-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
</style>
<style lang="scss" scoped>
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;

  & :deep(.bv-tooltip-content-container) {
    width: fit-content; 
    height: fit-content;
  }
}
.header-wrapper {
  flex: 0 0;
  padding: 20px 46px 24px;;
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

.content-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.footer-wrapper {
  position: absolute;
  bottom: 19px;
  left: 50%;
  transform: translateX(-50%);
}

.header-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .stepback {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.scroll-wrapper {
  height: 100%;
  padding: 0 5px;
}

.scroll-container {
  padding: 0 24px;
}

.celebrity-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
  grid-auto-rows: minmax(124px, 124px);
}

.celebrity-item {
  padding: 12px 17px;
  border-radius: 8px;
  display: flex;
  gap: 12px;
  cursor: pointer;

  &:hover {
    background: #F8F5FF;
  }

  & img {
    border-radius: 4px;
    width: 100px;
    height: 100px;
  }

  & .celebrity-name {
    max-width: 124px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 8px;
  }

  & .celebrity-size {
    color: #646A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 
  }
}

.hook-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding-bottom: 180px;
}

.hook-item {
  padding: 12px 18px 12px 12px;
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  cursor: pointer;
    
  &.active,
  &:hover {
    background: #F8F5FF;
  }

  & .hook-detail {
    display: flex;
    gap: 12px;

    & .right {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }


  & .hook-cover-box {
    position: relative;
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background: #ebedef;

    & > .cover {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }

    & > .icon-play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 36px;
      height: 36px;
    }

    & > video {
      width: 100%;
      height: 100%;
      border-radius: 4px;
    }
  }

  & :deep(.el-slider) {
    width: 187px;
  }

  & :deep(.el-slider__runway) {
    height: 4px;
    background: #e5e7eb;
  }

  & :deep(.el-slider__bar) {
    height: 4px;
  }

  & :deep(.el-slider__button) {
    border: 1px solid #e5e7eb;
    transition: none;
    width: 14px;
    height: 14px;
    box-shadow: 2px 2px 4px #eae8e8;
    transform: none;
    &:hover {
      width: 14px;
      height: 14px;
      transform: none;
    }
  }

  & :deep(.el-slider__button-wrapper) {
    top: -16px;
    &:hover {
      :deep(.el-slider__button) {
        transform: none;
      }
    }
  }

  & .hook-name {
    color: #000;
  }

  & .hook-control {
    margin-top: auto;
  }

  & .hook-duration {
    color: #646A73;
  }
}
</style>