<template>
  <div class="h-full w-full flex items-center justify-center">
    <GenerateLoading
      :showSteps="copyGenerateSteps"
      :current="currentStep"
      :stepsLength="generateSteps.length"
      :stepNum="2"
      :perStepPercent="[80, 90, 100]"
    />

    <SubscribeDialog
      :visible="subscribeModalVisible"
      @close="subscribeModalVisible = false"
      :showIntroduction="false"
    />
  </div>
</template>

<script setup>
import { onBeforeUnmount, onMounted, watch, onUnmounted } from 'vue';
import { useLoading } from '@/components/common/generateLoading/useLoading.js';
import { useModalManager } from '@/components/common/custom-modal/instance';
import { useSingleMessage } from '@/utils';
import { sessionGetItem } from '@/utils/storage';
import { routeMap } from '@/components/common/generateLoading/data.js';
import SubscribeDialog from '@/layout/components/workspace/subscribeDialog/index.vue';

import { useRoute, useRouter } from 'vue-router';
import { renderSimilarVideo } from '@/api/similarVideo/index.js';
import { validImport } from '@/pages/createVideos/utils/import';
import { useTrackStore } from '@/store/modules/track';
import { useSimilarVideo } from './store/index.js';

const similarVideoStore = useSimilarVideo();
const {
  formParams,
  selectedScriptItem,
  selectedMediaId,
  selectedStyleId,
  selectedVoiceId,
} = storeToRefs(similarVideoStore);

const { collectData, track, clearEventData } = useTrackStore();
const Message = useSingleMessage();
const modalManager = useModalManager();
const router = useRouter();
const route = useRoute();

const { currentStep, clearTimer, generateSteps, copyGenerateSteps, renderVO } =
  useLoading();
const leftPage = ref(false);
const reqId = ref('');

const subscribeModalVisible = ref(false);
const handleGenerated = async () => {
  // 生成接口

  const params = {
    description: formParams.value?.description,
    mediaId: selectedMediaId.value,
    name: formParams.value?.name,
    voiceId: selectedVoiceId.value,
    prompt: selectedScriptItem.value?.new,
    mid: formParams.value?.mid,
    mids: formParams.value?.mids,
    url: formParams.value?.url,
    visStyle: selectedStyleId.value,
  };

  const { data } = await renderSimilarVideo(params);

  if (!data) {
    Message.error('Please try again');
    router.replace({
      path: '/similar-video/setting',
    });
    return;
  }

  const trackHelper = (reqId) => {
    collectData('boolvideo_text_input', {
      video_type: 'similar_video',
      idea_prompt: selectedScriptItem.value.new,
      reqId,
    });
    track('boolvideo_text_input');
    clearEventData('boolvideo_text_input');
  };

  trackHelper(data);

  router.replace({
    path: route.path,
    query: {
      reqId: data,
    },
  });

  reqId.value = data;
};

watch(
  () => renderVO.value,
  (renderVO) => {
    if (renderVO) {
      clearTimer();
      if (leftPage.value) {
        return;
      }

      router.replace({
        path: '/scene-editor',
        query: {
          draftId: renderVO.draftId,
          videoType: 'similar_video',
        },
      });
    }
  }
);

onMounted(() => {
  reqId.value = route.query.reqId;
  if (reqId.value) {
    return;
  }
  handleGenerated();
});

onBeforeUnmount(() => {
  leftPage.value = true;
  clearTimer();
  setTimeout(() => {
    clearTimer();
  }, 1000);
});

onUnmounted(() => {
  clearTimer();
});
</script>
