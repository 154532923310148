<script setup>
import { getImageList } from "@/api/library";
import { useNetwork } from "@/composables";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import Header from "./libheader.vue";
import Empty from "./empty.vue";
import Loading from "./loading.vue";
import Previewer from "../../../space/components/material-previewer/index.vue";

const props = defineProps({
  currentTab: {
    type: String,
    default: "image",
  },
});

const { materialTab, getXY, addNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();
const { assertNetworkError } = useNetwork();

const materialWidth = inject("materialWidth");
const list = ref(null);
const files = ref([]);
const file = ref({});
const hoverFile = ref(null);
const previewer = ref(null);
const page = ref(1);
const total = ref(0);
const keyword = ref("");
const ratio = ref("");
const color = ref("");
const loading = ref(false);
const empty = computed(() => files.value.length === 0);
const disabled = computed(
  () => loading.value || files.value.length >= total.value
);
watch([materialWidth, files, materialTab, () => props.currentTab], resize, {
  flush: "post",
  immediate: true,
});

function resize() {
  if (props.currentTab !== "image" || materialTab.value !== "library") {
    return;
  }
  if (!empty.value && list.value) {
    let columns = 5;
    let width = 0;
    const gap = 10;
    const padding = 16;
    const MIN_WIDTH = 74;

    while (
      (width =
        (materialWidth.value - gap * (columns - 1) - padding * 2) / columns) <
      MIN_WIDTH
    ) {
      columns--;
    }
    const hs = new Array(columns).fill(0);

    for (const element of list.value.children) {
      const w = parseFloat(element.dataset.width);
      const h = parseFloat(element.dataset.height);
      const height = h * (width / w);
      const index = hs.indexOf(Math.min(...hs));
      const left = (width + gap) * index;
      const top = hs[index];

      element.style.left = `${left}px`;
      element.style.top = `${top}px`;
      element.style.width = `${width}px`;
      element.style.height = `${height}px`;

      hs[index] += height + gap;
    }
    list.value.style.height = `${Math.max(...hs)}px`;
  }
  if (
    !list.value ||
    list.value.scrollHeight <
      list.value.parentElement.parentElement.clientHeight
  ) {
    getImages();
  }
}

async function getImages() {
  if (loading.value) {
    return;
  }
  loading.value = true;
  const data = { page: page.value, size: 20 };

  if (keyword.value) {
    data.keyword = keyword.value;
  }
  if (ratio.value) {
    data.shape = ratio.value;
  }
  if (color.value) {
    data.color = color.value;
  }
  const response = await getImageList(data);

  if (response.success) {
    const { records } = response.data;

    total.value = response.data.total;
    files.value = files.value.concat(records);
    page.value++;
  }
  loading.value = false;
}

function search() {
  files.value = [];
  page.value = 1;
  getImages();
}

async function addAsset(eventName, e, file, target) {
  assertNetworkError();
  const conf = {
    ...getXY(),
    src: file.previewUrl,
    hdUrl: file.preview1080Url,
    type: "image",
    fit: "contain",
    duration: 90,
    materialMeta: {
      width480: file.width480,
      width1080: file.width1080,
      url1080: file.preview1080Url,
    },
  };
  if (eventName === "mousemove") {
    Object.assign(dragData, {
      x: e.clientX,
      y: e.clientY,
      target,
      meta: conf,
    });
  } else {
    await addNode(conf, { shouldRefresh: true, shouldAnnotate: true });
    commit();
    updateDraft();
  }
}
function preview(value) {
  file.value = { ...value, type: "image" };
  previewer.value.open = true;
}
</script>
<template>
  <Header
    v-model:keyword="keyword"
    v-model:ratio="ratio"
    v-model:color="color"
    @search="search"
  />
  <el-scrollbar>
    <el-skeleton animated :loading="loading && page === 1">
      <template #template>
        <el-skeleton-item v-for="(_, i) in Array(20)" variant="rect" :key="i" />
      </template>
      <empty v-if="empty" />
      <div v-else class="list-wrapper">
        <div
          ref="list"
          class="file-list"
          v-infinite-scroll="getImages"
          :infinite-scroll-immediate="false"
          :infinite-scroll-distance="50"
          :infinite-scroll-disabled="disabled"
        >
          <div
            class="file-item"
            v-for="(file, i) in files"
            :key="i"
            :data-width="file.width"
            :data-height="file.height"
            @mouseenter="hoverFile = file"
            @mouseleave="hoverFile = null"
          >
            <div class="image-wrapper">
              <img
                v-drag:[file]="addAsset"
                :src="file.coverPic"
                loading="lazy"
                draggable="false"
              />
            </div>
            <span
              v-show="hoverFile === file"
              class="preview"
              @mousedown.stop="preview(file)"
            >
              <svg-icon name="editor_library_preview" clickable :size="18" />
            </span>
          </div>
        </div>
        <loading v-show="loading && page > 1" />
      </div>
      <previewer ref="previewer" :showArrow="false" :record="file" />
    </el-skeleton>
  </el-scrollbar>
</template>
<style scoped>
.list-wrapper {
  padding: 0 16px;
}
.file-list {
  position: relative;
}
:deep(.el-skeleton) {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(74px, 1fr));
  padding: 0 16px;
}
.file-item {
  position: absolute;
}
.image-wrapper,
:deep(.el-skeleton__item) {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #f3f5f7;
  border-radius: 4px;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}
:deep(.el-skeleton__item) {
  height: 70px;
}
.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.file-item .preview {
  padding: 3px;
  position: absolute;
  right: 4px;
  bottom: 4px;
  border-radius: 2px;
  background-color: rgba(38, 38, 38, 0.7);
}
</style>
