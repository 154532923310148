<template>
  <div 
    class="preview-container"
    @click="$emit('click')"
  >
    <div 
      v-if="loadingScene === scene"
      class="image-loading"
    >
      <svg-icon
        name="icon_loading"
        color="#875EFF"
        :size="24"
      />
    </div>
    <div
      v-else-if="node?.conf.active === false"
      class="image-box"
    >
      <div 
        class="image-mask"
      >
        <svg-icon
          name="script_upload"
          color="#FFFFFF"
          :size="18"
        />
        <span>Replace</span>
      </div>
      <div 
        class="image-error-box"
      >
        <svg-icon 
          name="icon_error_image"
          :size="30"
        />
      </div>
    </div>
    <div 
      v-else-if="node?.conf.coverPic"
      class="image-box"
    > 
      <div 
        class="image-mask"
      >
        <svg-icon
          name="script_upload"
          color="#FFFFFF"
          :size="18"
        />
        <span>Replace</span>
      </div>
      <div 
        class="load"
        v-if="!ready"
      >
        <svg-icon
          name="icon_loading"
          color="#BBBFC4"
          :size="18"
        />
      </div>
      <div 
        class="mask-bg"
        v-else
      >
      </div>
      <img 
        class="media" 
        ref="coverRef"
        :src="node?.conf.coverPic" 
        alt="" 
      />
    </div>
    <div v-else class="image-upload">
      <svg-icon
        name="script_upload"
        color="#646A73"
        :size="18"
      />
      <span>Upload</span>
    </div>
  </div>
</template>

<script setup>
import { useScriptStore } from "../../stores/script";
import { observeNode } from "../../utils/observe";

defineEmits(["click"]);
const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
  node: {
    type: Object,
    default: {},
  },
});

const { 
  loadingScene,
} = useScriptStore();

const ready = ref(false);
const coverRef = ref(null);

const setup = () => {
  const { node } = props;
  if (node) {
    observeNode(node);
    if (node?.conf.active === true && node?.conf.coverPic) {
      if (coverRef.value) {
        coverRef.value.onload = () => {
          ready.value = true;
        }
      }
    }
  }
};

onMounted(setup);
</script>

<style lang="scss" scoped>
.preview-container {
  width: 90px;
  height: 90px;
  border-radius: 4px;
}

.image-loading {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: #FFFFFF;

  & > svg {
    animation: rotate 1s linear infinite;
  }
}

.image-box {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer;
  border-radius: 4px;

  & > .media {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
    position: relative;
    z-index: 2;
  }

  & > .load {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background-color: #FFFFFF;

    & > svg {
      position: absolute;
      top: calc(50% - 9px);
      left: calc(50% - 9px);
      animation: rotate 1s linear infinite;
    }
  }

  & > .mask-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    z-index: 1;
    background-image: url("@/assets/images/common/pixel_grid.png");
  }
}

.hook-container.active .image-mask,
.scene-wrapper:hover .image-mask {
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.6);;
}

.image-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: 4px;
  transition: all 0.2s;
  z-index: 3;

  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.6);;
  }

  & > span {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
  }

  & > svg {
    color: #FFFFFF;
    margin-bottom: 4px;
  }
}

.image-upload {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #FFFFFF;
  cursor: pointer;

  & > span {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: #646A73;
  }

  & > svg {
    margin-bottom: 4px;
  }
}

.image-error-box {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: #FFFFFF;
}
</style>