<template>
  <div
    class="hook-container"
  >
    <svg-icon
      name="hook_selected_triangle" 
      class="decoration" 
      :size="30"
    />
    <BrollCover
      :scene="scene"
      @click="materialClick"
    />
    <div class="hookinfo-container">
      <div 
        class="hook-header"
      >
        <div class="title">
          <svg-icon
            name="script_voice"
            :size="18"
          />  
          <span>{{ `Voice : ${scene.hookName}` }}</span>
        </div>
      </div>
    </div>
  </div>
  <div
    class="tools-wrapper"
  >
    <HookTools
      :scene="scene"
      :index="index"
    />
  </div>
  <BrollDialog
    v-model="brollDialogVisible"
    @confirm="handleReplaceMaterial"
  />
</template>

<script setup>
import HookTools from "./hooktools.vue";
import BrollCover from "./brollcover.vue";
import BrollDialog from "../dialog/brolldialog.vue";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";
import { replaceAllVoice } from "../../utils/scene";
import { useMessage } from "@/utils";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
});

const {
  ratio,
  videoType,
  updateDraft,
} = useDraftStore();
const {
  defaultVoiceStyle,
  defaultVoiceName,
  defaultLanguage,
  creator,
  pause,
  refresh,
} = useScriptStore();
const message = useMessage();

const editorLoading = inject("editorLoading");
const brollDialogVisible = ref(false);

const materialClick = () => {
  pause();
  brollDialogVisible.value = true;
  // collectData("boolvideo_scene_edit_click", {
  //   click: "replace_media",
  //   video_type: videoType.value,
  // });
  // track("boolvideo_scene_edit_click");
};

const handleReplaceMaterial = async (voice) => {
  if (voice.voiceId === defaultVoiceName.value) {
    message.success("Currently applying the same voice");
    return ;
  }

  pause();
  editorLoading.value = true;

  const params = {
    language: defaultLanguage.value,
    voiceName: voice.voiceId,
    voiceStyle: defaultVoiceStyle.value,
  }
  replaceAllVoice(params)
    .then(() => {
      Object.assign(props.scene, {
        hookName: voice.name,
        materialCoverPic: voice.coverPic,
      });
      triggerRef(creator);
      nextTick(refresh);
      updateDraft();
      message.success("Processing completed");
    })
    .catch((e) => {
      console.error(e);
      message.error("Processing failed");
    })
    .finally(() => {
      editorLoading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.hook-container {
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #F8F5FF;
  display: flex;
  gap: 12px;

  & > .decoration {
    position: absolute;
    left: -1px;
    top: -1px;
  }
}

.hookinfo-container {
  flex: 1 1;


  .title {
    display: flex;
    align-items: center;
    color: #646A73;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; 

    & > svg {
      margin-right: 6px;
    }
  }
}

.tools-wrapper {
  margin-top: 8px;
  opacity: 0;
  pointer-events: none;
}
</style>