<template>
  <div
    ref="sceneRef"
    class="scene-container"
    :class="{ active }"
    @click="sceneClick(scene)"
  >
    <SceneCover
      :key="mKey"
      :scene="scene"
      :index="index"
      :node="materialNode"
      @click="materialClick"
    />
    <div class="sceneinfo-container">
      <SceneHeader
        :key="sKey"
        :scene="scene"
        :index="index"
        :node="speechNode"
        @voiceClick="voiceClick"
      />
      <SceneInput
        ref="inputRef"
        :key="tKey"
        :scene="scene"
        :nodes="textNodes"
        v-click-outside:[sceneRef]="() => sceneBlur(index)"
      />
    </div>
  </div>
  <div
    class="tools-wrapper"
  >
    <SceneTools
      :scene="scene"
      :index="index"
    />
  </div>
  <MaterialDialog
    v-if="materialVisble"
    v-model="materialVisble"
    :scene="scene"
    :defaultValue="materialNode?.conf.sceneKeywords"
    @confirm="handleReplaceMaterial"
  />
  <VoiceDialog
    v-model="voiceVisible"
    v-if="voiceVisible"
    :node="speechNode"
    @confirm="handleReplaceVoice"
  />
</template>

<script setup>
import MaterialDialog from "../dialog/materialdialog.vue";
import VoiceDialog from "../dialog/voicedialog.vue";
import SceneHeader from "./sceneheader.vue";
import SceneCover from "./scenecover.vue";
import SceneTools from "./scenetools.vue";
import SceneInput from "./sceneinput.vue";
import { useTrackStore } from "@/store/modules/track";
import { useSettingStore } from "../../stores/setting";
import { useScriptStore } from "../../stores/script";
import { useDraftStore } from "../../stores/draft";
import { getUpdateSceneInfo } from "@/api/script";
import { normalizeConfig } from "../../utils/conf";
import { parseNode } from "../../utils/scene";
import { replaceSource, getFit } from "../../utils/node";
import { calcRate, extractLocale, getVideoDuration } from "../../utils";
import { useMessage } from "@/utils";
import { validImport } from "@/pages/createVideos/utils/import";
import { useModalManager } from "@/components/common/custom-modal/instance";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
  index: {
    type: Number,
    default: 0,
  },
});

const { 
  defaultVoiceStyle,
  defaultVoiceName,
  loadingScene,
  activeScene,
  creator,
  scenes,
  pause,
  seekTo,
  refresh,
  removeText,
  setActiveScene,
  setLoadingScene,
  setDefaultVoice,
  updateMaterialSpeed,
  updateSpeechSpeed,
  updateOverallDuration,
  updateDuration,
  addSpeechNode,
  addImageNode,
  addVideoNode,
  addSubtitleNode,
  removeNode,
} = useScriptStore();
const { 
  ratio, 
  videoType, 
  updateDraft 
} = useDraftStore();
const { setting, getGlobalFontSetting } = useSettingStore();
const { collectData, track } = useTrackStore();
const modalManager = useModalManager();
const message = useMessage();

const sceneRef = ref(null);
const editorLoading = inject("editorLoading");
const materialVisble = ref(false);
const voiceVisible = ref(false);
const materialNode = ref(null);
const speechNode = ref(null);
const inputRef = ref(null);
const textNodes = ref([]);
const sceneLanguage = computed(() => {
  if (videoType.value.startsWith("similar_video")) {
    return props.scene.defaultLanguage;
  }
  else {
    const voiceName = defaultVoiceName.value;
    return extractLocale(voiceName);
  }
});
const active = computed(() =>
  props.scene === activeScene.value &&
  loadingScene.value !== props.scene
);
const mKey = computed(() => {
  let key = "";
  if (materialNode.value) {
    key = materialNode.value.id;
  }
  return key;
});
const sKey = computed(() => {
  let key = "";
  if (speechNode.value) {
    key = speechNode.value.id;
  }
  return key;
});
const tKey = computed(() => {
  let key = "";
  if (textNodes.value && textNodes.value.length > 0) {
    
    key = textNodes.value.reduce((res, n) =>
      res + n.id
    , "");
    key = Array.from(key).sort().join("");
  }
  return key;
});

watch(
  () => props.scene.nodes,
  (newNodes) => {
    if (!props.scene.sceneId || !newNodes) return;
    const { subtitles, speech, primary } = parseNode(newNodes);
    textNodes.value = subtitles;
    speechNode.value = speech;
    materialNode.value = primary;
  },
  {
    immediate: true,
  },
);

const sceneClick = (scene) => {
  setActiveScene(scene);
  seekTo(scene.start);
};

const materialClick = () => {
  pause();
  materialVisble.value = true;
  collectData("boolvideo_scene_edit_click", {
    click: "replace_media",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
};

const voiceClick = () => {
  pause();
  voiceVisible.value = true;
  collectData("boolvideo_scene_edit_click", {
    click: "voiceover",
    video_type: videoType.value,
  });
  track("boolvideo_scene_edit_click");
};

const handleReplaceMaterial = async (file) => {
  if (materialNode.value) {
    const extraConf = {
      fit: getFit(file),
    };
    replaceSource(props.scene, file, extraConf)
      .then(() => {
        updateDraft();
        message.success("Processing completed");
      })
  }
  else {
    const {
      name,
      preview480Url,
      preview1080Url,
      coverPic,
      width480,
      width1080,
      type,
    } = file;
    const conf = {
      name,
      sceneId: props.scene.sceneId,
      src: preview480Url,
      hdUrl: preview1080Url,
      coverPic,
      sceneKeywords: "",
      fit: "cover",
      materialMeta: {
        width480,
        width1080,
        url480: preview480Url,
        url1080: preview1080Url,
      },
    };
    conf.fit = getFit(file);
    const { start, end } = props.scene;
    const duration = end - start;
    switch(type) {
      case "video":
        const videoDuration = await getVideoDuration(conf.src).then(res => res * 30);
        let speed = 1;
        if (videoDuration < duration) {
          speed = calcRate(videoDuration, duration);
        }
        conf.transparent = file.aiType === "videoBgRemove";
        await addVideoNode({ start, end, speed , ...conf });
        break;
      case "image":
        await addImageNode({ start, end, ...conf });
        break;
    }

    triggerRef(creator);
    updateDraft();
    await refresh();
    seekTo(props.scene.start)
    message.success("Processing completed");

  }
  materialVisble.value = false;
};

const handleTextChange = async (value) => {
  if (value.trim() === "") {
    removeText(props.scene)
      .then(() => {
        updateDraft();
        message.success("Processing completed");
      });
    return;
  };

  const voiceName = speechNode.value?.conf.voiceName || defaultVoiceName.value;
  const voiceStyle = speechNode.value?.conf.voiceStyle || defaultVoiceStyle.value;
  const speed = speechNode.value?.conf.speed || 1;
  const volume = speechNode.value?.conf.volume || 1;
  const language = sceneLanguage.value;
  const params = {
    speed,
    volume,
  };
  const apiParams = {
    language,
    voiceName,
    voiceStyle,
    text: value,
    size: ratio.value,
    lab11: videoType.value.startsWith("similar_video"),
  };

  pause();
  setLoadingScene(props.scene);

  const res = await getUpdateSceneInfo(apiParams);
  if (!res.success) {
    const { code, msg } = res;
    if (validImport(code)) {
      modalManager.applyTemplate("importFailed", {msg});
    }
    inputRef.value?.resetText();
    message.error("Processing failed")
  }
  else {
    await updateSpeech(props.scene, params, res.data)
    .then(() => {
      triggerRef(creator);
      nextTick(refresh);
      updateDraft();
      seekTo(props.scene.start);
      message.success("Processing completed");
    })
    .catch((e) => {
      console.error(e);
      message.error("Processing failed");
    });
  }

  setLoadingScene(null);
};

const handleReplaceVoice = async (value, applyAll = false) => {
  const replaceHelper = (scene) => {
    return new Promise((resolve, reject) => {
      const { subtitles, text } = parseNode(scene.nodes);
      if (applyAll && subtitles.length === 0) {
        resolve(false);
      }
      else if (!applyAll && inputRef.value.userInput === "") {
        resolve(false)
      }
      else {
        const { language, voiceName, voiceStyle } = value;
        const newText = applyAll ? text: inputRef.value.userInput;
        const apiParams = {
          size: ratio.value,
          text: newText,
          language,
          voiceName,
          voiceStyle,
          lab11: videoType.value.startsWith("similar_video"),
        };
        getUpdateSceneInfo(apiParams)
          .then((res) => {
            const { code, msg } = res;
            if (validImport(code)) {
              modalManager.applyTemplate("importFailed", {msg});
              reject();
            }
            else {
              const newScene = {
                ...res.data, 
                sceneId: scene.sceneId
              };
              resolve(newScene);
            }
          })
      }
    });
  }
  pause();

  let sceneArray = [];
  if (applyAll) {
    sceneArray = scenes.value;
    editorLoading.value = true;
    const { language, voiceName, voiceStyle } = value;
    setDefaultVoice({language, voiceName, voiceStyle});
  }
  else {
    sceneArray = [props.scene];
    setLoadingScene(props.scene);
  }

  const proList = [];
  for (const scene of sceneArray) {
    const { speech } = parseNode(scene.nodes);
    if (!speech) continue;
    const {
      voiceName,
      voiceStyle = "general",
    } = speech.conf;
    const needReplace =
      voiceName !== value.voiceName ||
      voiceStyle !== value.voiceStyle;
    if (needReplace) {
      proList.push(replaceHelper(scene));
    }
    else {
      const {
        volume,
        speed,
      } = speech.conf;
      const needUpdate =
        speed !== value.speed ||
        volume !== value.volume;
      if (needUpdate) {
        await updateSpeechSpeed(scene, value)
      }
    }
  }

  // Update after request returns
  await Promise.all(proList)
    .then(async (newScenes) => {
      for (const newScene of newScenes) {
        if (Boolean(newScene)) {
          const { sceneId } = newScene;
          const scene = scenes.value.find(
            item => item.sceneId === sceneId
          );
          await updateSpeech(scene, value, newScene);
        }
      }
    })
    .then(() => {
      triggerRef(creator);
      nextTick(refresh);
      seekTo(sceneArray[0].start);
      updateDraft();
      message.success("Processing completed");
    })
    .catch((e) => {
      console.error(e);
      inputRef.value.resetText();
      message.error("Processing failed");
    });

  applyAll ? editorLoading.value = false: setLoadingScene(null);
};

const updateSpeech = async (scene, params, newConf) => {
  const { volume = 1, speed = 1 } = params;

  const confHelper = (conf) => {
    const { screen } = creator.value;
    return {
      video: normalizeConfig(screen, "video", conf.children[0].children[0].children[0]),
      text: normalizeConfig(screen ,"text", conf.children[1].children[0]),
    };
  };
  const { subtitles, speech, primary, effect } = parseNode(scene.nodes);

  const { end } = newConf;
  const oldDuration = scene.end - scene.start;
  const oldSpeed = 1;
  const newStart = scene.start;
  const step = oldSpeed / speed;
  const newEnd = Math.round(end * step) + newStart;
  const newDuration = newEnd - newStart;
  const diff = newDuration - oldDuration;

  const { voiceText, config } = newConf;
  const textConf = { 
    ...confHelper(JSON.parse(config)).text,
    ...getGlobalFontSetting(),
    y: setting.value.posY,
    
  };
  delete textConf.type;
  for (const item of voiceText) {
    const { start, end } = item.voiceTime;
    const conf = textConf;
    await addSubtitleNode({
      ...conf,
      speed,
      start: Math.round(start * step) + newStart,
      end: Math.round(end * step) + newStart,
      text: item.text,
      sceneId: scene.sceneId,
      wordBoundars: item.wordBoundars,
    });
  }

  if (primary) {
    await updateMaterialSpeed(primary, newStart, newEnd);
  }

  const { voiceUrl, voiceName, voiceStyle } = newConf;
  if (speech) {
    speech.conf.speed = speed;
    speech.conf.volume = volume;
    speech.conf.start = newStart;
    speech.conf.end = newEnd;
    speech.conf.src = voiceUrl;
    speech.conf.voiceName = voiceName;
    speech.conf.voiceStyle = voiceStyle;
  }
  else {
    await addSpeechNode({
      start: newStart,
      end: newEnd,
      src: voiceUrl,
      speed,
      volume,
      voiceName,
      voiceStyle,
      sceneId: scene.sceneId,
    });
  }

  if (effect) {
    effect.conf.start = newStart;
    effect.conf.end = newEnd;
  }
  // Clear captions
  for (const node of subtitles) {
    removeNode(node);
  }
  // Update duration
  const pos = scenes.value.findIndex(
    item => item.sceneId === scene.sceneId
  );
  for (let i = pos + 1; i < scenes.value.length; i++) {
    updateDuration(scenes.value[i], diff);
  }
  await updateOverallDuration(diff);
  scene.start = newStart;
  scene.end = newEnd;
};

const sceneBlur = () => {
  if (voiceVisible.value || loadingScene.value) return;

  const { scene } = props;
  const { text } = parseNode(scene.nodes);
  if (text === inputRef.value.userInput) return;
  handleTextChange(inputRef.value.userInput);
};
</script>

<style lang="scss" scoped>
.scene-container {
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #F3F5F7;
  display: flex;
  gap: 12px;
}

.scene-container.active{
  border-color: #A378FF;
}

.sceneinfo-container {
  flex: 1 1;
}

.tools-wrapper {
  margin-top: 8px;
  transition: opacity 0.2s linear 0s;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.scene-container.active ~ .tools-wrapper,
.scene-wrapper:hover .tools-wrapper{
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
}
</style>