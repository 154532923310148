<template>
  <el-scrollbar v-if="isMounted">
    <div
      class="collection"
      v-if="list && list.length"
      v-infinite-scroll="handleLoadData"
      :infinite-scroll-immediate="true"
      :infinite-scroll-distance="10"
      :infinite-scroll-delay="200"
    >
      <div
        v-for="item in list"
        class="collection-item"
        @click="handleClick(item)"
      >
        <div class="image-box">
          <img :src="item.coverPic" class="coverPic" />
        </div>
        <div class="detail">
          <p>{{ item.name }}</p>
          <p class="number">
            {{ item.size }} {{ selectedStyleId == 1 ? 'Clips' : 'Stickers' }}
          </p>
        </div>
      </div>
    </div>
    <div v-else class="h-full flex justify-center items-center">
      <svg-icon
        name="icon_empty"
        style="color: #bbbfc4; height: 200px"
      ></svg-icon>
    </div>
  </el-scrollbar>
</template>

<script setup>
import { ref } from 'vue';
const props = defineProps({
  list: Array,
  selectedStyleId: Number,
});

const emit = defineEmits('selected', request);
const isMounted = ref(false)
const handleClick = (item) => {
  emit('selected', item);
};

const handleLoadData = () => {
  emit('request', props.selectedStyleId);
};

onMounted(async () => {
  await nextTick();
  isMounted.value = true
});
</script>

<style lang="scss" scoped>
.collection {
  padding: 21px 16px 120px 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(236px, 1fr));
  grid-auto-rows: minmax(124px, 124px);

  .collection-item {
    cursor: pointer;
    height: 124px;
    padding: 12px 17px;
    display: flex;
    border-radius: 8px;
    &:hover {
      background: #f8f5ff;
    }
  }

  .image-box {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    background: #ebedef;
  }

  .coverPic {
    width: 100px;
    height: 100px;
    border-radius: 4px;
    object-fit: contain;
  }

  .detail {
    margin-left: 12px;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
  }

  .number {
    color: #646a73;
    margin-top: 6px;
    line-height: 22px;
  }
}
</style>
