import creatorModule from '@boolv/creator';
import { calcRate } from './index';
import { useVideoCliper } from './instance';
import { useScriptStore } from '../stores/script';
import { useDraftStore } from '../stores/draft';

const { nodes, replaceNode, creator } = useScriptStore();
const { ratio } = useDraftStore();
const videoCliper = useVideoCliper();
const commonConfigProp = {
  imageAndVideo: ['anchor', 'start', 'end', 'fit', 'opacity', 'rotate', 'scale', 'x', 'y', 'mask', 'keyframes'],
};

export function imageToVideo(node, conf) {
  const extractedProps = {};

  if (node.type !== 'image') {
    throw new Error('node is not an image node');
  }

  for (const prop of commonConfigProp.imageAndVideo) {
    if (prop in node.conf) {
      extractedProps[prop] = node.conf[prop];
    }
  }

  return new creatorModule.Video({...extractedProps, ...conf});
}

export function videoToImage(node, conf) {
  const extractedProps = {};

  if (node.type !== 'video') {
    throw new Error('node is not a video node');
  }

  for (const prop of commonConfigProp.imageAndVideo) {
    if (prop in node.conf) {
      extractedProps[prop] = node.conf[prop];
    }
  }

  return new creatorModule.Image({...extractedProps, ...conf});
}

export async function replaceSource(scene, file, extraConf = {}) {
  let replacedNode = null;
  let node = null;
  for (const item of nodes.value) {
    const isMaterial = 
      item.conf.sceneId === scene.sceneId &&
      item.parent.type === "scene"; 
    if (isMaterial) {
      node = item;
      replacedNode = item;
    }
  } 
  if (!node) return;
  const nodeDuration = node.conf.end - node.conf.start;
  const replacedProps = {
    name: file.name,
    sceneId: scene.sceneId,
    src: file.preview480Url,
    hdUrl: file.preview1080Url,
    coverPic: file.coverPic,
    sceneKeywords: node.conf.sceneKeywords,
    materialMeta: {
      width480: file.width480,
      width1080: file.width1080,
      url480: file.preview480Url,
      url1080: file.preview1080Url,
    },
    ...extraConf
  };
  
  if (file.type === 'image') {
    if (node.type === 'image') {
      if (node.conf.active) {
        Object.assign(node.conf, replacedProps);
      }
    } else if (node.type === 'video') {
      replacedNode = videoToImage(node, replacedProps);
    }
  } else if (file.type === 'video') {
    replacedProps.transparent = file.aiType === 'videoBgRemove';
    replacedProps.volume = 0;
    let speed = 1;
    const videoDuration = file.duration * 30;
    const sceneDuration = scene.end - scene.start;
    if (videoDuration < sceneDuration) {
      speed = calcRate(videoDuration, sceneDuration);
    }
    replacedProps.speed = speed;
    if (nodeDuration < videoDuration) {
      const clipData = await new Promise(res => videoCliper.open(file.preview480Url, nodeDuration / 30, res));
      replacedProps.ss = clipData.start * 30 | 0;
    } else {
      replacedProps.ss = 0;
    }

    if (node.type === 'image') {
      replacedNode = imageToVideo(node, replacedProps);
    } else if (node.type === 'video') {
      if (node.conf.active) {
        Object.assign(node.conf, replacedProps);
      }
    }
  }

  if (replacedNode !== node) {
    await replaceNode(node, replacedNode);
  }
  else if(!node.conf.active) {
    const newNode = node.clone();
    replacedProps.active = true;
    Object.assign(newNode.conf, replacedProps);
    await replaceNode(node, newNode);
  }
}

export function getFit(file) {
  let fit = "contain";
  const { width, height } = file;
  switch(ratio.value) {
    case "9:16":
      if(Math.abs(width / height - 9 / 16) <= 0.11){
        fit = "cover"
      }
      break;
    case "1:1":
      if(Math.abs(width / height - 1) <= 0.19){
        fit = "cover"
      }
      break;
    case "16:9":
      if(Math.abs(width / height - 16 / 9) <= 0.28){
        fit = "cover"
      }
      break;
  }
  return fit;
}