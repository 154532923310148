<template>
  <el-dialog
    class="script-material-dialog"
    :model-value="modelValue" 
    :show-close="false"
    :align-center="true"
    :before-close="handleClose"
  >
    <div class="dialog-container">
      <div class="header-wrapper">
        <Header
          :tabList="tabList.map(item => item.tabName)"
          v-model:currentTab="currentTab"
          @close="handleClose"
        />
      </div>
      <div class="content-wrapper">
        <component 
          v-model:material="material"
          :defaultValue="defaultValue"
          :is="currentContent" 
          :scene="scene"
          @select="(v) => selectFile = v"
        />
      </div>
      <div class="footer-wrapper">
        <Footer 
          :selectFile="selectFile"
          @confirm="replace"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script setup>
import Header from "./materialheader.vue";
import Footer from "./materialfooter.vue";
import PexelsContent from "./pexelscontent.vue";
import AiContent from "./aicontent.vue";
import ProjectContent from "./projectcontent.vue";
import CloudContent from "./cloudcontent.vue";

import { useTrackStore } from "@/store/modules/track";
import { useDraftStore } from "../../stores/draft";

const { 
  videoType, 
} = useDraftStore();
const { collectData, track, clearEventData } = useTrackStore();

const props = defineProps({
  scene: {
    type: Object,
  },
  modelValue: {
    type: Boolean,
  },
  defaultValue: {
    type: String,
  },
});
const emits = defineEmits([
  "update:modelValue",
  "confirm"
]);

const tabList = [
  {
    tabName: "Library",
    component: PexelsContent,
  },
  // {
  //   tabName: "AI generate",
  //   component: AiContent,
  // },
  {
    tabName: "Project",
    component: ProjectContent,
  },
  {
    tabName: "Cloud",
    component: CloudContent,
  },
];
const currentTab = ref("Library");
const selectFile = ref(null);
const material = ref(null);
const currentContent = computed(() => 
   tabList.find((item) => item.tabName === currentTab.value).component
);

watch(
  currentTab,
  () => {
    selectFile.value = null;
  },
);

const handleClose = () => {
  emits("update:modelValue", false);
};

const replace = () => {
  const trackHelper = () => {
    collectData("boolvideo_scene_edit_click", {
      click: "replace_success",
      video_type: videoType.value,
      replace_type: `${currentTab.value.toLowerCase()}_${selectFile.value.type}`
    });
    track("boolvideo_scene_edit_click");
    clearEventData("boolvideo_scene_edit_click");
  };
  trackHelper();
  emits("confirm", selectFile.value);
};
</script>

<style>
.script-material-dialog {
  width: 65%;
  height: 80%;
  min-width: 840px;
  min-height: 500px;
  border-radius: 4px;
}

.script-material-dialog .el-dialog__body {
  padding: 0;
  margin: 0;
  height: 100%;
}
.script-material-dialog .el-dialog__header{
  padding: 0;
  margin: 0;  
}
</style>
<style lang="scss" scoped>
:deep(.el-progress) {
  width: 66px;
  height: 3px;
}
:deep(.el-progress-bar__outer) {
  height: 3px !important;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 0;
}
:deep(.el-progress-bar__inner) {
  background-color: #ffffff;
  border-radius: 0;
}
.dialog-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.header-wrapper {
  flex: 0 0;
  padding: 24px 26px 0;
}

.content-wrapper {
  flex: 1 1 auto;
  overflow: hidden;
}

.footer-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 19px;
}
</style>