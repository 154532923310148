<script setup>
import {
  defaultFontFamily,
  getFontFamilyByLabel,
  textPreview,
} from "@/constants/text";
import { useNetwork } from "@/composables";
import Material from "./material.vue";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";

const { getXY, addNode, creator } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();

const textOptions = [
  {
    id: 2,
    text: "Title",
    fontWeight: "bold",
    fontSize: 64,
  },
  {
    id: 3,
    text: "Headline",
    fontWeight: "bold",
    fontSize: 48,
  },
  {
    id: 4,
    text: "Subheadline",
    fontWeight: "bold",
    fontSize: 40,
  },
  {
    id: 5,
    text: "Caption",
    fontWeight: "bold",
    fontSize: 32,
    y: creator.value.screen.height * 0.8,
  },
];

const presetOptions = [
  {
    id: 6,
    scale: 0.87,
    text: "Today's activity",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontWeight: "bold",
    fontSize: 54,
    dropShadow: true,
    dropShadowAngle: 0.75,
    dropShadowColor: "#FFFFFF",
    dropShadowDistance: 7,
    stroke: "#FFFFFF",
    strokeThickness: 0.18,
    url: textPreview.todaySActivity,
  },
  {
    id: 7,
    scale: 0.87,
    text: "New content",
    fontFamily: getFontFamilyByLabel("Inter"),
    fill: "#00FF40",
    fontSize: 49,
    fontWeight: "bold",
    strokeThickness: 0.21,
    url: textPreview.newContent,
  },
  {
    id: 8,
    scale: 1.18,
    text: "Best Life",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 25,
    stroke: "#FFFFFF",
    background: "#000000",
    url: textPreview.bestLifeBlack,
  },
  {
    id: 9,
    scale: 1.18,
    text: "Best life",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 22,
    stroke: "#FFFFFF",
    background: "#FFFFFF",
    url: textPreview.bestLifeWhite,
  },
  {
    id: 10,
    text: "I Love It",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 43,
    fontWeight: "bold",
    stroke: "#FF00F1",
    strokeThickness: 0.17,
    url: textPreview.loveIt,
  },
  {
    id: 11,
    text: "Shop Now",
    fill: "#FFFA00",
    fontSize: 34,
    fontWeight: "bold",
    fontStyle: "italic",
    strokeThickness: 0.34,
    url: textPreview.shopNow,
  },
  {
    id: 12,
    scale: 1.18,
    text: "50% OFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 22,
    fontWeight: "bold",
    stroke: "#FFDA00",
    background: "#FFFA00",
    url: textPreview.off,
  },
  {
    id: 13,
    text: "Experience",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 41,
    fontWeight: "bold",
    stroke: "#000000",
    strokeThickness: 0.25,
    background: "#9DFF0D",
    url: textPreview.experience0,
  },
  {
    id: 14,
    scale: 0.87,
    text: "Daily",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 34,
    strokeThickness: 0.18,
    url: textPreview.dailyWhite,
  },
  {
    id: 15,
    scale: 0.87,
    text: "Daily",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontWeight: "bold",
    fontSize: 34,
    stroke: "#FFFFFF",
    strokeThickness: 0.18,
    url: textPreview.dailyBlack,
  },
  {
    id: 16,
    scale: 0.87,
    text: "Coupon",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 53,
    fontWeight: "bold",
    stroke: "#FF1010",
    background: "#FF3232",
    url: textPreview.coupon,
  },
  {
    id: 17,
    scale: 1.18,
    text: "Travel tips",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fontSize: 37,
    fontWeight: "bold",
    fontStyle: "italic",
    stroke: "#FFFFFF",
    background: "#FFFFFF",
    url: textPreview.travelTips,
  },
  {
    id: 18,
    text: "Today outfit",
    fill: "#FF00E8",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fontSize: 43,
    fontWeight: "bold",
    stroke: "#FFFFFF",
    strokeThickness: 0.17,
    url: textPreview.todayOutfit,
  },
  {
    id: 19,
    scale: 1.18,
    text: "Bright spot",
    fill: "#00FF8E",
    fontFamily: getFontFamilyByLabel("Inter"),
    fontSize: 46,
    fontWeight: "bold",
    dropShadow: true,
    dropShadowAngle: 0.66,
    dropShadowBlur: -95,
    stroke: "#FFDA00",
    url: textPreview.brightSpot,
  },
  {
    id: 20,
    scale: 0.87,
    text: "Flash sale",
    fill: "#FFFFFF",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fontSize: 40,
    fontWeight: "bold",
    stroke: "#E20000",
    strokeThickness: 0.18,
    url: textPreview.flashSale,
  },
  {
    id: 21,
    scale: 1.18,
    text: "Vintage",
    fill: "#FF0000",
    fontFamily: getFontFamilyByLabel("Lobster"),
    fontSize: 66,
    dropShadow: true,
    dropShadowAngle: 0.96,
    dropShadowColor: "#FFDD00",
    dropShadowDistance: 6,
    stroke: "#FFDA00",
    url: textPreview.vintage,
  },
  {
    id: 22,
    scale: 1.18,
    text: "Amazing",
    fontFamily: getFontFamilyByLabel("Roboto"),
    fill: "#FFFFFF",
    fontSize: 37,
    fontWeight: "bold",
    fontStyle: "italic",
    stroke: "#FFFFFF",
    background: "#000000",
    url: textPreview.amazing,
  },
  {
    id: 23,
    scale: 1.18,
    text: "Retro Vibe",
    fontFamily: getFontFamilyByLabel("Inter"),
    fill: "#FFFBF3",
    fontSize: 46,
    fontWeight: "bold",
    dropShadow: true,
    dropShadowAngle: 0.66,
    dropShadowDistance: 0,
    stroke: "#FF6700",
    strokeThickness: 0.25,
    url: textPreview.retroVibe,
  },
];

async function addAsset(eventName, e, file, target) {
  assertNetworkError();
  const conf = {
    ...getXY(),
    type: "text",
    fontFamily: defaultFontFamily,
    fill: "#000000",
    duration: 90,
    ...file,
  };
  if (eventName === "mousemove") {
    Object.assign(dragData, {
      x: e.clientX,
      y: e.clientY,
      target,
      meta: conf,
    });
  } else {
    await addNode(conf, { shouldRefresh: true, shouldAnnotate: true });
    commit();
    updateDraft();
  }
}

async function addDefaultText() {
  await addNode(
    {
      ...getXY(),
      type: "text",
      text: "Text",
      sourceId: 1,
      fontSize: 24,
      fill: "#000000",
      fontFamily: defaultFontFamily,
      duration: 90,
    },
    { shouldRefresh: true, shouldAnnotate: true }
  );
  commit();
  updateDraft();
}
</script>
<template>
  <Material title="Text">
    <el-scrollbar>
      <div class="material-content">
        <el-button size="large" @click="addDefaultText">
          <svg-icon name="editor_plus" color="#1C1B1E" :size="18" />
          <span>Add Text</span>
        </el-button>
        <div class="text-group">
          <button
            v-for="option of textOptions"
            :key="option.id"
            :style="{ fontSize: `${Math.round(option.fontSize / 2.6)}px` }"
            v-drag:[option]="addAsset"
          >
            {{ option.text }}
          </button>
        </div>
        <div class="preset-group">
          <button
            v-for="option of presetOptions"
            :key="option.id"
            v-drag:[option]="addAsset"
          >
            <img :src="option.url" draggable="false" />
          </button>
        </div>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style lang="scss" scoped>
:deep(.el-button.el-button--large) {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 27px;
  border-radius: 4px;
}
:deep(.el-button.el-button--large:hover) {
  background-color: #f5f6f7;
}
.text-group {
  margin-bottom: 14px;
}
.text-group button {
  width: 100%;
  height: 42px;
  margin-bottom: 16px;
  border-radius: 4px;
  font-weight: 500;
  color: #000000;
  background-color: #f3f5f7;
  transition: background-color 200ms;
}
.text-group button:hover {
  background-color: #e8e9ec;
}
.preset-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
.preset-group button {
  width: 100%;
  height: 74px;
  padding: 26px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: 500;
  color: #000000;
  background-color: #f3f5f7;
  transition: background-color 200ms;
}

.preset-group button:hover {
  background-color: #e8e9ec;
}
.preset-group img {
  width: 100%;
  height: 100%;
}
</style>
