<script setup>
import { getStickerList } from "@/api/resources";
import { useNetwork } from "@/composables";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import Material from "./material.vue";

const { getXY, addNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();

const hoverFile = ref(null);
const files = ref([]);
const loading = ref(false);

onMounted(getStickers);

async function getStickers() {
  loading.value = true;
  const { success, data } = await getStickerList();

  if (success) {
    files.value = data.reduce((group, item) => {
      const tag = item.tag || "Animated";
      if (!group[tag]) group[tag] = [];
      group[tag].push(item);
      return group;
    }, {});
  }
  loading.value = false;
}

async function addAsset(eventName, e, file, target) {
  assertNetworkError();
  const conf = {
    ...getXY(),
    coverPic: file.coverPic,
    sourceId: file.id,
    src: file.url,
    type: "sticker",
    scale: 0.5,
    mode: file.type === 1 ? "animated" : "static",
    duration: 90,
    materialMeta: {
      width480: file.width480,
      width1080: file.width1080,
      url1080: file.preview1080Url,
    },
  };
  if (eventName === "mousemove") {
    Object.assign(dragData, {
      x: e.clientX,
      y: e.clientY,
      target,
      meta: conf,
    });
  } else {
    await addNode(conf, { shouldRefresh: true, shouldAnnotate: true });
    commit();
    updateDraft();
  }
}
</script>
<template>
  <Material title="Sticker">
    <el-scrollbar>
      <div class="material-content">
        <el-skeleton animated :loading="loading">
          <template #template>
            <el-skeleton-item variant="text" />
            <div class="skeleton-list">
              <el-skeleton-item
                v-for="(_, i) in Array(20)"
                variant="rect"
                :key="i"
              />
            </div>
            <el-skeleton-item variant="text" />
            <div class="skeleton-list">
              <el-skeleton-item
                v-for="(_, i) in Array(20)"
                variant="rect"
                :key="i"
              />
            </div>
          </template>
          <div
            class="col"
            v-for="(key, i) in Object.keys(files).sort()"
            :key="i"
          >
            <div class="file-header">
              <span>{{ key }}</span>
            </div>
            <div class="file-list">
              <div
                v-for="file in files[key]"
                class="file-item"
                :key="file.id"
                @mouseenter="hoverFile = file"
                @mouseleave="hoverFile = null"
              >
                <div class="image-wrapper" v-drag:[file]="addAsset">
                  <img
                    loading="lazy"
                    draggable="false"
                    :src="
                      hoverFile === file && file.dynamicPic
                        ? file.dynamicPic
                        : file.coverPic
                    "
                  />
                </div>
              </div>
            </div>
          </div>
        </el-skeleton>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
.material-content .col {
  padding-bottom: 8px;
}
.file-header {
  height: 54px;
  display: flex;
  align-items: center;
}
.file-header span {
  font-size: 14px;
  line-height: 22px;
  color: #060606;
}
.file-list,
.skeleton-list {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));
}
.image-wrapper,
:deep(.el-skeleton__rect) {
  width: 100%;
  height: 68px;
  padding: 7px;
  border: 1px solid #e8e9ec;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  transition: border-color 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}
:deep(.el-skeleton__text) {
  margin: 18px 0;
  height: 20px;
  width: 50%;
}
.image-wrapper:hover {
  border-color: #875eff;
}
.image-wrapper img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}
</style>
