<script setup>
import { getBrandKitList, getBrandKitDetail } from "@/api/brandkit";
import { useModalManager } from "@/components/common/custom-modal/instance";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";
import Material from "./material.vue";

const modalManager = useModalManager();
const { dragData } = useDrag();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { getXY, addNode } = useCreatorStore();
const brandKits = ref([]);
const brandKit = ref(null);
const currentBrandKit = ref(null);

onMounted(getBrandKits);
watch(currentBrandKit, updateBrandKit);

function submit() {
  commit();
  updateDraft();
}

async function getBrandKits() {
  const response = await getBrandKitList();

  if (response.success) {
    brandKits.value = response.data.map((item) => ({
      label: item.name,
      value: item.bkId,
      defaultUse: item.defaultUse,
    }));
    currentBrandKit.value = brandKits.value.find((b) => b.defaultUse).value;
  }
}

async function updateBrandKit(newBrandKit) {
  const response = await getBrandKitDetail(newBrandKit);

  if (response.success) {
    brandKit.value = response.data;
  }
}

async function addAsset(eventName, e, file, target) {
  if (brandKit.value.lock) {
    modalManager.applyTemplate("upgradeTips", { code: 60001 });
  } else {
    const conf = {
      ...getXY(),
      type: "image",
      scale: 0.5,
      fit: "none",
      src: file.url,
      duration: 90,
    };
    if (eventName === "mousemove") {
      Object.assign(dragData, {
        x: e.clientX,
        y: e.clientY,
        target,
        meta: conf,
      });
    } else {
      await addNode(conf, { shouldRefresh: true, shouldAnnotate: true });
      submit();
    }
  }
}
</script>
<template>
  <Material title="Brand kit">
    <div class="select-container-wapper">
      <bv-select
        v-if="brandKits.length > 0"
        placement="bottom-end"
        popper-class="brand-kit-select"
        :teleported="false"
        :show-border="false"
        :show-select-icon="false"
        :options="brandKits"
        :model-value="currentBrandKit"
        @change="updateBrandKit"
      >
        <template #suffix>
          <svg-icon name="editor_material_arrow_down" :size="20" />
        </template>
      </bv-select>
    </div>
    <el-scrollbar>
      <div class="material-content">
        <div v-if="brandKit?.logos.length" class="logo-container">
          <div class="logo-header">
            <span class="logo-title">Logo</span>
            <svg-icon v-if="brandKit.lock" name="editor_lock" :size="16" />
          </div>
          <div class="logo-list">
            <div
              v-for="(file, i) in brandKit.logos"
              v-drag:[file]="addAsset"
              class="logo"
              :key="i"
            >
              <img loading="lazy" draggable="false" :src="file.url" />
            </div>
          </div>
        </div>
        <div class="slogan-list" v-if="brandKit?.slogans.length > 0">
          <div class="slogan-header">
            <span class="slogan-title">Slogan</span>
            <svg-icon v-if="brandKit.lock" name="editor_lock" :size="16" />
          </div>
          <div
            v-for="(slogan, i) in brandKit.slogans"
            class="slogan-item"
            :key="i"
            :class="{ lock: brandKit.lock }"
          >
            {{ slogan }}
          </div>
        </div>
        <div class="story-content" v-if="brandKit?.aboutBrand">
          <div class="story-header">
            <span class="story-title">Brand story</span>
            <svg-icon v-if="brandKit.lock" name="editor_lock" :size="16" />
          </div>
          <div class="story" :class="{ lock: brandKit.lock }">
            {{ brandKit.aboutBrand }}
          </div>
        </div>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
:deep(.el-scrollbar) {
  height: calc(100% - 50px);
}
.select-container-wapper {
  width: 100%;
  height: 50px;
  padding: 0 18px;
  position: relative;
}
:deep(.select-wapper) {
  padding: 0;
  border-bottom: 1px solid #e5e7eb;
  transition: none;
}
:deep(.select-wapper.is-focus) {
  border-bottom-width: 0;
}
:deep(.select-value) {
  color: #060606;
}
.logo-container {
  margin-bottom: 30px;
}
.logo-header {
  padding: 15px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo-title {
  color: #060606;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  margin-right: 6px;
}
.logo-list {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, 68px);
}
.logo {
  width: 100%;
  height: 68px;
  border: 1px solid #e8e9ec;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  transition: border-color 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
  background-image: url("@/assets/images/common/pixel_grid.png");
  background-size: contain;
}
.logo:hover {
  border-color: #875eff;
}
.logo img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.slogan-list {
  margin-bottom: 36px;
}
.slogan-header,
.story-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}
.slogan-title,
.story-title,
.slogan-item,
.story {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.slogan-title,
.story-title {
  color: #060606;
}
.slogan-item,
.story {
  color: #646a73;
  user-select: text;
  cursor: text;
  word-break: break-word;
}
.slogan-item.lock,
.story.lock {
  cursor: default;
  user-select: none;
}
.slogan-item + .slogan-item {
  margin-top: 30px;
}
:deep(.select-container-wapper .el-popper) {
  top: 50px !important;
  left: 0 !important;
  right: 0 !important;
  transform: none !important;
}
:deep(.select-container-wapper .brand-kit-select) {
  box-shadow: none;
  border: none;
  border-radius: 0;
  padding: 8px 8px;
}
:deep(.bv-select-dropdown.brand-kit-select .bv-select-item) {
  border-radius: 4px;
  padding: 8px 10px;
}
:deep(.bv-select-dropdown.brand-kit-select ul) {
  border-radius: 0;
}
:deep(.bv-select-dropdown.brand-kit-select::after) {
  content: "";
  position: absolute;
  left: 8px;
  right: 8px;
  height: 1px;
  background-color: #e5e7eb;
}
</style>
