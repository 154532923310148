<template>
  <div class="script-layout">
    <Header />
    <Content />
    <Assistance v-if="globalDomain === 1" :loading="!creator"/>
    <Loading v-if="!creator || editorLoading"/>
  </div>
</template>

<script setup lang="jsx">
import Header from "./header.vue";
import Content from "./content.vue";
import Loading from "./loading.vue";
import Assistance from "@/components/ai-assistant/assistance.vue";
import debugConfig from "../../test/demo.json";
import { useModalManager } from "@/components/common/custom-modal/instance";
import { translateAllScene } from "../../utils/translate";
import { useTrackStore } from "@/store/modules/track";
import { useSettingStore } from "../../stores/setting";
import { useScriptStore } from "../../stores/script";
import { useDraftStore } from "../../stores/draft";
import { getLanguageList } from "@/api/language";
import { getDraftDetail } from "@/api/draft";
import { useRetryAsync } from "@/utils";

const {
  creator,
  scenes,
  start,
  destroy,
  setLanguageList,
  materialChecker,
} = useScriptStore();
const { 
  title, 
  videoType, 
  setRatio, 
  setVideoType,
  setDraftId,
  setReqId,
  setRid,
} = useDraftStore();
const { collectData, track } = useTrackStore();
const { initGlobalSetting } = useSettingStore();
const modalManager = useModalManager();
const route = useRoute();
const router = useRouter();

const globalDomain = inject("globalDomain");
const guideStep = ref(1);
const editorLoading = ref(false);

const setup = async () => {
  const materialHelper = () => {
    if (!materialChecker()) {
      modalManager.applyTemplate("warn", {
        title: "Failed to retrieve current media",
        content:
          "Please replace the media manually.",
        icon: (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M12 22C14.7614 22 17.2614 20.8807 19.0711 19.0711C20.8807 17.2614 22 14.7614 22 12C22 9.2386 20.8807 6.7386 19.0711 4.92893C17.2614 3.11929 14.7614 2 12 2C9.2386 2 6.7386 3.11929 4.92893 4.92893C3.11929 6.7386 2 9.2386 2 12C2 14.7614 3.11929 17.2614 4.92893 19.0711C6.7386 20.8807 9.2386 22 12 22Z" fill="#FF8800"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 18.5C12.6904 18.5 13.25 17.9404 13.25 17.25C13.25 16.5597 12.6904 16 12 16C11.3097 16 10.75 16.5597 10.75 17.25C10.75 17.9404 11.3097 18.5 12 18.5Z" fill="white"/>
            <path d="M12 6V14" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        ),
        footer: (
          <Space size={12} reversed>
            <CommonButton size="extra-small" onclick={modalManager.modal.onClose}>
              Got it
            </CommonButton>
          </Space>
        ),
        modalClass: "material-warn-tip",
        key: "material-warn-tip",
      });
    }
  };

  const setupHelper = async (renderConfig) => {
    const retryStart = useRetryAsync(start);
    await retryStart(renderConfig);
    initGlobalSetting(JSON.parse(renderConfig).globalSetting);
    materialHelper();
    getLanguageList().then((r) => {
      setLanguageList(languageHelper(r.data));
    });
  };

  const languageHelper = (list) => {
    let temp = [];
    list.forEach(item => {
      temp = [ ...temp, ...item.localInfoList ];
    });
    return temp;
  };

  const trackHelper = (value) => {
    const { renderType, draftId, reqId, rid } = value;
    setVideoType(renderType);
    setDraftId(draftId);
    setReqId(reqId);
    setRid(rid);
  };

  const { translateParams, debug } = route.query;
  let draftId = route.query.draftId;
  let rid = "";
  let reqId = "";
  let renderConfig = JSON.stringify(debugConfig);
  let ratio = "9:16";

  if( translateParams ) {
    const { draftId: tDraftId, language: tLanguage } = JSON.parse(translateParams);
    const newDraftId = await translateAllScene(tDraftId, tLanguage);
    
    if (newDraftId) {
      draftId = newDraftId;
    }
  }

  if (debug) {
    await setupHelper(renderConfig);
    return;
  }

  if (!draftId) return;

  const { success, data } = await getDraftDetail(draftId);
  console.log(success);
  if (success) {
    renderConfig = data.renderConfig;
    ratio = data.ratio;
    rid = data.rid;
    reqId = data.reqId;
    setRatio(ratio);
    title.value = data.name;
    await setupHelper(renderConfig);
    trackHelper(data);

    collectData("boolvideo_scene_edit_click", {
      draftId,
      rid,
      reqId,
    });

    collectData("boolvideo_editor_page_view", {
      video_type: videoType.value,
    });
    router.replace({ 
      query: { 
        ...route.query, 
        translateParams: undefined,
        videoType: videoType.value,
        draftId, 
      },
    });
  }
  track("boolvideo_editor_page_view");
};

onMounted(setup);
onBeforeUnmount(() => {
  destroy();
  scenes.value = [];
});

provide("guideStep", guideStep);
provide("editorLoading", editorLoading);
</script>

<style lang="scss" scoped>
.script-layout {
  height: 100vh;
  width: 100vw;
  min-height: 640px;
  min-width: 1256px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

:deep(.modal-container.material-warn-tip) {
  width: 415px;
}
</style>