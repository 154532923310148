<script setup>
import {
  useCreatorStore,
  useHistoryStore,
  useDraftStore,
  useCopyStore,
  useKeyboard,
} from "../../stores";
import { clamp, frameToHmss } from "../../utils";
import CropModal from "./cropmodal.vue";

const {
  magnet,
  autoSnap,
  canPlay,
  activeNodeMap,
  displayFrame,
  currentFrame,
  playing,
  timeline,
  totalFrame,
  sliceNode,
  removeActiveNodes,
  play,
  pause,
  seekTo,
  frameToWidth,
  adsorb,
  refresh,
} = useCreatorStore();

const { canUndo, canRedo, undo, redo, commit } = useHistoryStore();
const { canCut, canPaste, canPasteKeyframe, copy, paste, cut, pasteKeyframe } = useCopyStore();
const { updateDraft } = useDraftStore();
const { keyCode, pressed } = useKeyboard();
const timelineRef = inject("timeline");
const showCrop = ref(false);
const imageNode = computed(() => {
  if (activeNodeMap.size === 1) {
    for (const node of activeNodeMap.values()) {
      if (node.type === "image") {
        return node;
      }
      return null;
    }
  }
  return null;
});
const canSlice = computed(() => {
  for (const node of activeNodeMap.values()) {
    if (node.type === "transition") {
      continue;
    }
    if (node.startFrame < currentFrame.value && currentFrame.value < node.endFrame) {
      return true;
    }
  }
  return false;
});
const canCrop = computed(() => Boolean(imageNode.value));
const canRemove = computed(() => activeNodeMap.size > 0);
const canScaleDown = computed(
  () => canPlay.value && timeline.scale > timeline.minScale,
);
const canScaleUp = computed(
  () => canPlay.value && timeline.scale < timeline.maxScale,
);

onMounted(() => {
  window.addEventListener("keydown", keyDown);
  window.addEventListener("wheel", wheel, { passive: false });
  document.addEventListener("visibilitychange", visibilityChange);
});
onUnmounted(() => {
  window.removeEventListener("keydown", keyDown);
  window.removeEventListener("wheel", wheel);
  document.removeEventListener("visibilitychange", visibilityChange);
});

function submit() {
  commit();
  updateDraft();
}

function visibilityChange() {
  if (document.visibilityState === "hidden") {
    pause();
  }
}

function keyDown(e) {
  if (["TEXTAREA", "INPUT"].includes(e.target.tagName)) {
    return;
  }
  if (canPlay.value) {
    switch (e.code) {
      case "Space":
        playing.value ? pause() : play();
        break;
      case "ArrowLeft":
        seekTo(currentFrame.value - 1);
        e.preventDefault();
        break;
      case "ArrowRight":
        seekTo(currentFrame.value + 1);
        e.preventDefault();
        break;
      case "KeyP":
        switchMagnet();
        break;
      case "KeyN":
        switchAutoSnap();
        break;
      case "KeyZ":
        e.shiftKey && scaleTofit();
        break;
    }
  }
  if (pressed.value) {
    switch (e.code) {
      case "KeyZ":
        e.shiftKey ? canRedo.value && redoFunc() : canUndo.value && undoFunc();
        e.preventDefault();
        break;
      case "KeyB":
        canSlice.value && sliceFunc();
        e.preventDefault();
        break;
      case "Minus":
        canScaleDown.value && scaleDown(1);
        e.preventDefault();
        break;
      case "Equal":
        canScaleUp.value && scaleUp(1);
        e.preventDefault();
        break;
      case "KeyC":
        copy();
        break;
      case "KeyX":
        canCut.value && cutFunc();
        break;
      case "KeyV":
        pasteFunc();
        break;
    }
  }
}

function wheel(e) {
  if (e.ctrlKey) {
    e.preventDefault();
  }
}

function undoFunc() {
  undo();
  updateDraft();
}

function redoFunc() {
  redo();
  updateDraft();
}

function sliceFunc() {
  sliceNode();
  submit();
}

function removeFunc() {
  removeActiveNodes();
  submit();
}

function crop(src) {
  imageNode.value.conf.src = imageNode.value.conf.hdUrl = src;
  submit();
}

function scaleTimeline(value) {
  timeline.scale = value;
  timeline.autoFit = false;
}

function switchMagnet() {
  magnet.value = !magnet.value;

  if (magnet.value) {
    adsorb();
    nextTick(refresh);
    submit();
  }
}

function switchAutoSnap() {
  autoSnap.value = !autoSnap.value;
}

function scaleTofit() {
  const newScale = Math.round(frameToWidth(timelineRef.value.clientWidth) / frameToWidth(totalFrame.value * 1.2) / timeline.baseline);
  timeline.scale = clamp(newScale, timeline.minScale, timeline.maxScale);
}

function scaleDown(value) {
  timeline.scale = Math.max(timeline.minScale, timeline.scale - value);
  timeline.autoFit = false;
}

function scaleUp(value) {
  timeline.scale = Math.min(timeline.maxScale, timeline.scale + value);
  timeline.autoFit = false;
}

function cutFunc() {
  cut();
  submit();
}

function pasteFunc() {
  if (canPaste.value) {
    paste();
    submit();
  }
  if (canPasteKeyframe.value){
    pasteKeyframe();
    submit();
  }
}
</script>
<template>
  <div class="timeline-tools">
    <div class="timeline-tools-left">
      <icon-button
        name="editor_undo"
        :tip="`Undo(${keyCode}+Z)`"
        :tip-delay="0"
        :disabled="!canUndo"
        :size="18"
        @click="undoFunc"
      />
      <icon-button
        name="editor_redo"
        :tip="`Redo(${keyCode}+Shift+Z)`"
        :tip-delay="0"
        :disabled="!canRedo"
        :size="18"
        @click="redoFunc"
      />
      <icon-button
        name="editor_slice"
        :tip="`Split(${keyCode}+B)`"
        :tip-delay="0"
        :disabled="!canSlice"
        :size="18"
        @click="sliceFunc"
      />
      <icon-button
        v-show="canCrop"
        name="editor_crop"
        tip="Crop"
        :tip-delay="0"
        :size="18"
        @click="showCrop = true"
      />
      <icon-button
        name="editor_delete"
        tip="Delete(⌫)"
        :tip-delay="0"
        :disabled="!canRemove"
        :size="18"
        @click="removeFunc"
      />
      <div class="timeline-tools-center">
        <svg-icon
          clickable
          v-show="playing"
          name="editor_pause"
          :size="24"
          @click="pause"
        />
        <svg-icon
          clickable
          v-show="!playing"
          name="editor_play"
          color="#1C1B1E"
          :disabled="!canPlay"
          :size="24"
          @click="play"
        />
        <span class="time" :class="{ disabled: !canPlay }">
          <span class="current-time">{{ frameToHmss(displayFrame) }}</span>
          <span class="duration">{{ ` / ${frameToHmss(totalFrame)}` }}</span>
        </span>
      </div>
    </div>

    <div class="timeline-tools-right">
      <icon-button 
        name="editor_magnet"
        tip="Switch main track magnet(P)"
        :tip-delay="0"
        :color="magnet ? '#6741FF' : '#1C1B1E'"
        :active="magnet"
        :disabled="!canPlay"
        :size="18"
        @click="switchMagnet"
      />
      <icon-button 
        name="editor_auto_snap"
        tip="Switch auto snapping(N)"
        :tip-delay="0"
        :color="autoSnap ? '#6741FF' : '#1C1B1E'"
        :active="autoSnap"
        :disabled="!canPlay"
        :size="18"
        @click="switchAutoSnap"
      />
      <icon-button 
        name="editor_timeline_fit"
        tip="Timeline zoom to fit(Shift+Z)"
        :tip-delay="0"
        :disabled="!canPlay"
        :size="18"
        @click="scaleTofit"
      />
      <div class="timeline-scale-group">
        <icon-button
          name="editor_scale_down"
          :tip="`Timeline zoom out(${keyCode}+-)`"
          :disabled="!canScaleDown"
          :size="18"
          @click="scaleDown(10)"
        />
        <bv-slider
          :model-value="timeline.scale"
          :disabled="!canPlay"
          :min="timeline.minScale"
          :max="timeline.maxScale"
          :step="1"
          :show-tooltip="false"
          @update:model-value="scaleTimeline"
        />
        <icon-button
          name="editor_scale_up"
          :tip="`Timeline zoom in(${keyCode}++)`"
          :disabled="!canScaleUp"
          :size="18"
          @click="scaleUp(10)"
        />
      </div>
    </div>
    <crop-modal
      v-if="canCrop"
      v-model="showCrop"
      :src="imageNode.conf.hdUrl"
      @change="crop"
    />
  </div>
</template>
<style scoped>
.timeline-tools {
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e8e9ec;
  border-bottom: 1px solid #e8e9ec;
  padding: 5px 20px;
  background-color: #ffffff;
  position: relative;
}

.timeline-tools-left {
  flex: 1 1;
  display: flex;
  align-items: center;
  position: relative;
}

.timeline-tools-left .icon-button {
  margin-right: 24px;
}

.timeline-tools-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.timeline-tools-center svg {
  margin-right: 14px;
}

.timeline-tools-center .time {
  font-size: 14px;
  height: 22px;
}

.timeline-tools-center .time.disabled .current-time,
.timeline-tools-center .time.disabled .duration {
  color: #bbbfc4;
}

.timeline-tools-center .time .current-time {
  color: #000000;
  line-height: 22px;
  font-variant: tabular-nums;
}

.timeline-tools-center .time .duration {
  color: #646a73;
  line-height: 22px;
}

.timeline-tools-right {
  height: 100%;
  position: absolute;
  top: 0;
  right: 24px;
  display: flex;
  align-items: center;
}

.timeline-tools-right > .icon-button {
  margin-right: 16px;
}

.timeline-scale-group {
  height: 100%;
  display: flex;
  align-items: center;
}

:deep(.timeline-scale-group .el-slider) {
  width: 94px;
  margin-left: 12px;
  margin-right: 12px;
}
</style>
