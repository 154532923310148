import { useDraftStore } from "../stores/draft";
import { useScriptStore } from "../stores/script";
import { getUpdateSceneInfo } from "@/api/script";
import { validImport } from "@/pages/createVideos/utils/import";
import modalInstance from "@/components/common/custom-modal/instance";

export function parseNode(nodes) {
  let subtitles = [];
  let videos = [];
  let images = [];
  let audios = [];
  let speech = null;
  let primary = null;
  let effect = null;
  let text = "";
  let sticker = "";
  for (const node of nodes) {
    if (node.conf.type === "subtitle") {
      subtitles.push(node);
      text += node.conf.text;
    } else if (node.conf.type === "effect") {
      effect = node;
    } else if (node.conf.type === "speech") {
      speech = node;
    } else if (node.conf.type === "sticker") {
      sticker = node;
    } else if (node.parent?.type === "scene") {
      primary = node;
    } else if (node.conf.type === "video") {
      videos.push(node);
    } else if (node.conf.type === "image") {
      images.push(node);
    } else if (node.conf.type === "audio") {
      audios.push(node);
    }
  }
  return {
    text,
    subtitles,
    effect,
    speech,
    primary,
    sticker,
    videos,
    images,
    audios,
  };
}

export async function replaceAllVoice(params) {
  const { ratio, videoType, } = useDraftStore();
  const { scenes, updateSpeech, setDefaultVoice } = useScriptStore();

  const { language, voiceName, voiceStyle } = params;
  const replaceHelper = (scene) => {
    return new Promise((resolve, reject) => {
      const { subtitles, text } = parseNode(scene.nodes);
      if (subtitles.length === 0) {
        resolve(false);
      }
      else {
        const newText = text;
        const apiParams = {
          size: ratio.value,
          text: newText,
          language: language,
          voiceName: voiceName,
          voiceStyle: voiceStyle,
          lab11: videoType.value.startsWith("similar_video"),
        };
        getUpdateSceneInfo(apiParams)
          .then((res) => {
            const { code, msg } = res;
            if (validImport(code)) {
              modalInstance.modalManager.applyTemplate("importFailed", {msg});
              reject();
            }
            else {
              const newScene = {
                ...res.data, 
                sceneId: scene.sceneId
              };
              resolve(newScene);
            }
          });
      }
    })
  };

  const sceneArray = scenes.value.filter(
    item => !item.type || item.type === "default"
  );
  setDefaultVoice({ voiceName });
  const proList = [];
  for (const scene of sceneArray) {
    const { speech } = parseNode(scene.nodes);
    if (!speech) continue;
    const needReplace = voiceName !== speech.conf.voiceName;
    if (needReplace) {
      proList.push(replaceHelper(scene));
    }
  }

  return Promise.all(proList)
    .then(async (newScenes) => {
      for (const newScene of newScenes) {
        if (newScene) {
          const { sceneId } = newScene;
          const scene = scenes.value.find(
            item => item.sceneId === sceneId
          );
          await updateSpeech(scene, {volume: 1, speed: 1}, newScene);
        }
      }
    })
}