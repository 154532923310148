<template>
  <div 
    class="hook-header"
  >
    <div class="left">
      <div class="title">{{ title }}</div>
    </div>
    <div 
      class="right"
      v-if="canPlay"
    >
      <div class="play">
        <svg-icon
          name="editor_play"
          :size="24"
          @click="playVideo(scene)"
        />
      </div>
      <span class="duration">
        {{ duration }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { frameToHms } from "../../utils";
import { observeNode } from '../../utils/observe';
import { useScriptStore } from '../../stores/script';

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
  node: {
    type: Object,
  },
  canPlay: {
    type: Boolean,
    default: true
  }
});

const { 
  seekTo,
  play,
  setScenePlaying,
} = useScriptStore();

const currentPlay = inject("currentPlay");
const title = computed(() => {
  const { scene } = props;
  switch(scene.type) {
    case "hook_start":
      return `Celebrity hook: ${scene.hookName}`;
    case "voice_sticker":
      return `Celebrity sticker : ${scene.hookName}`;
    case "viral_hook":
      return "Viral voiceover";
  }
});
const duration = computed(() => {
  const { start, end } = props.scene;
  const newDuration = frameToHms(end - start);
  return newDuration;
});

const playVideo = async (scene) => {
  currentPlay.value = scene;
  await seekTo(scene.start);
  await play();
  setScenePlaying(true);
};

const setup = () => {
  if (props.node) {
    observeNode(props.node);
  }
};

onMounted(setup);
</script>

<style lang="scss" scoped>
.hook-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #646A73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
  margin-bottom: 30px;

  .right {
    display: flex;
    align-items: center;
  }

  .right.disabled {
    pointer-events: none;
    color: #BBBFC4;

    & svg {
      color: #BBBFC4;
    }
  }
}

.play {
  cursor: pointer;
  margin-right: 12px;

  &:hover {
    color: #1C1B1E;
  }
}
</style>