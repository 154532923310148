<script setup>
import Material from "./material.vue";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";

const shapeOptions = [
  {
    name: "Rectangle",
    shape: "rect",
    fill: "#CACDD0",
    icon: "editor_rectangle",
    width: 300,
    height: 150,
    size: 46,
  },
  {
    name: "Circle",
    shape: "circle",
    fill: "#CACDD0",
    icon: "editor_circle",
    width: 250,
    height: 250,
    size: 50,
  },
  {
    name: "Triangle",
    shape: "triangle",
    fill: "#CACDD0",
    icon: "editor_triangle",
    width: 250,
    height: 216,
    size: 58,
  },
];

const { getXY, addNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { dragData } = useDrag();

async function addAsset(eventName, e, file, target) {
  const conf = {
    ...getXY(),
    type: "graphic",
    shape: file.shape,
    fill: file.fill,
    width: file.width,
    height: file.height,
    duration: 90,
  };
  if (eventName === "mousemove") {
    Object.assign(dragData, {
      x: e.clientX,
      y: e.clientY,
      target,
      meta: conf,
    });
  } else {
    await addNode(conf, { shouldRefresh: true, shouldAnnotate: true });
    commit();
    updateDraft();
  }
}
</script>
<template>
  <Material title="Graphic">
    <el-scrollbar>
      <div class="material-content">
        <div class="shape-list">
          <div
            v-for="option in shapeOptions"
            class="shape-item"
            :key="option.name"
          >
            <div class="image-wrapper" v-drag:[option]="addAsset">
              <svg-icon
                :name="option.icon"
                :color="option.fill"
                :size="option.size"
              />
            </div>
            <div class="title">{{ option.name }}</div>
          </div>
        </div>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
.shape-list {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(auto-fill, minmax(68px, 1fr));
  padding: 16px 0;
}
.image-wrapper {
  width: 100%;
  height: 68px;
  padding: 7px;
  border: 1px solid #e8e9ec;
  border-radius: 4px;
  overflow: hidden;
  transition: border-color 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  cursor: pointer;
}

.image-wrapper:hover {
  border-color: #875eff;
}

.title {
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
  margin-top: 8px;
}
</style>
