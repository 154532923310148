import { _get, _post, _put, _delete } from '@/utils/https';

const urlList = {
  renderSimilar: '/video/render/similar',
  similarScript: '/video/render/similar/script',
  similarRewrite: '/video/render/similar/script/rewrite',
  similarHookList: '/library/similar/hooks',
  similarStickersList: '/library/similar/stickers',
  similarVoiceList: '/library/similar/voice',
  similarScriptToVideo: '/video/render/similar_script',
  similarViralHook: '/library/similar/viralHook'
};

const { VITE_API_BASE } = import.meta.env;

export function generateSimilarScript (params) {
  return _post(VITE_API_BASE + urlList.similarScript, params);
}

export function similarScriptRewrite (params) {
  return _post(VITE_API_BASE + urlList.similarRewrite, params);
}

export function renderSimilarVideo (params) {
  return _post(VITE_API_BASE + urlList.renderSimilar, params);
}

export function renderSimilarScriptToVideo (params) {
  return _post(VITE_API_BASE + urlList.similarScriptToVideo, params);
}

export function getSimilarHookList (params) {
  return _post(VITE_API_BASE + urlList.similarHookList, params);
}

export function getSimilarStickersList (params) {
  return _post(VITE_API_BASE + urlList.similarStickersList, params);
}

export function getSimilarVoiceList (params) {
  return _post(VITE_API_BASE + urlList.similarVoiceList, params);
}

export function getSimilarViralHook (params) {
  return _post(VITE_API_BASE + urlList.similarViralHook, params);
}




