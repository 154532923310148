<template>
  <div
    class="hook-container"
    :class="{ active }"
    @click="sceneClick(scene)"
  >
    <svg-icon
      name="hook_selected_triangle" 
      class="decoration" 
      :size="30"
    />
    <HookCover
      :key="mKey"
      :scene="scene"
      :node="materialNode"
      @click="materialClick"
    />
    <div class="hookinfo-container">
      <HookHeader
        :key="aKey"
        :scene="scene"
        :node="audioNode"
        :canPlay="true"
      />
      <div class="hook-voiceover-wrapper">
        <bv-tip
          content="Replace viral voiceover"
          placement="bottom"
          :showAfter="200"
        >
          <div 
            class="hook-voiceover-container"
            @click="voiceoverClick"
          >
            <span class="voiceover-name">{{ scene.text }}</span>
            <svg-icon 
              class="icon-left"
              name="icon_left"
              color="#1C1B1E"
              :size="14"
            />
          </div>
        </bv-tip>
      </div>
    </div>
  </div>
  <div
    class="tools-wrapper"
  >
    <HookTools
      :scene="scene"
      :index="index"
    />
  </div>
  <MaterialDialog
    v-model="materialVisible"
    :scene="scene"
    @confirm="handleReplaceMaterial"
  />
  <ViralVoiceoverDialog
    v-model="voiceoverVisible"
    :scene="scene"
    @confirm="handleReplaceVoiceover"
  />

</template>

<script setup>
import { useMessage } from "@/utils";
import HookCover from "./hookcover.vue";
import HookTools from "./hooktools.vue";
import HookHeader from "./hookheader.vue";
import { parseNode, replaceAllVoice } from "../../utils/scene";
import { getFit ,replaceSource } from "../../utils/node";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";
import MaterialDialog from "../dialog/materialdialog.vue";
import ViralVoiceoverDialog from "../dialog/viralvoiceoverdialog.vue";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
});

const {
  updateDraft,
} = useDraftStore();
const {
  scenes,
  creator,
  activeScene,
  loadingScene,
  defaultLanguage,
  defaultVoiceStyle,
  updateMaterialSpeed,
  updateDuration,
  setActiveScene,
  getTrack,
  refresh,
  seekTo,
  pause,
} = useScriptStore();
const message = useMessage();

const audioNode = ref(null);
const materialNode = ref(null);
const materialVisible = ref(false);
const voiceoverVisible = ref(false);
const editorLoading = inject("editorLoading");
const active = computed(() =>
  props.scene === activeScene.value &&
  loadingScene.value !== props.scene
);
const mKey = computed(() => {
  let key = "";
  if (materialNode.value) {
    key = materialNode.value.id;
  }
  return key;
});
const aKey = computed(() => {
  let key = "";
  if (audioNode.value) {
    key = audioNode.value.id;
  }
  return key;
});

watch(
  () => props.scene.nodes,
  (newNodes) => {
    if (!props.scene.sceneId || !newNodes) return;
    const { audios, primary } = parseNode(newNodes);
    if (audios.length > 0) {
      audioNode.value = audios[0];
    }
    if (primary) {
      materialNode.value = primary;
    }
  },
  {
    immediate: true,
  },
);

const sceneClick = (scene) => {
  setActiveScene(scene);
  seekTo(scene.start);
};

const materialClick = () => {
  pause();
  materialVisible.value = true;
  // collectData("boolvideo_scene_edit_click", {
  //   click: "replace_media",
  //   video_type: videoType.value,
  // });
  // track("boolvideo_scene_edit_click");
};

const voiceoverClick = () => {
  pause();
  voiceoverVisible.value = true; 
};


const handleReplaceMaterial = (file) => {
  const { scene } = props;
  materialVisible.value = false;
  replaceSource(scene, file, { fit: getFit(file) })
    .then(() => {
      updateDraft();
      message.success("Processing completed");
    });
  
};

const replaceViralHook = async (media) => {
  const { scene } = props;
  const newEnd = Math.floor(media.duration * 30);
  const diff = newEnd - scene.end;
  const defaultScenes = scenes.value.filter(
    item => item.type === "default" || !item.type
  );

  if (diff !== 0) {
    for (const defaultScene of defaultScenes) {
      updateDuration(defaultScene, diff);
    };
  }

  // move bgm in timeline
  const bgmTrack = getTrack("audio");
  for (const node of bgmTrack.children) {
    node.conf.start += diff;
    node.conf.end += diff;
  }

  if (materialNode.value) {
    await updateMaterialSpeed(materialNode.value, 0, newEnd);
  }

  const audioConf = {
    end: newEnd,
    src: media.url
  };
  Object.assign(audioNode.value.conf, audioConf);
  
}

const handleReplaceVoiceover = async (media) => {
  pause();
  editorLoading.value = true;

  const newEnd = Math.floor(media.duration * 30);
  const params = {
    language: defaultLanguage.value,
    voiceName: media.voiceId,
    voiceStyle: defaultVoiceStyle.value
  };
  await replaceViralHook(media);
  replaceAllVoice(params)
    .then(() => {
      Object.assign(props.scene, {
        text: media.text,
        end: newEnd,
      });
      triggerRef(creator);
      nextTick(refresh);
      updateDraft();
      message.success("Processing completed");
    })
    .catch((e) => {
      console.error(e);
      message.error("Processing failed");
    })
    .finally(() => {
      editorLoading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.hook-container {
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #F8F5FF;
  display: flex;
  gap: 12px;

  & > .decoration {
    position: absolute;
    left: -1px;
    top: -1px;
  }
}

.hook-container.active {
  border-color: #A378FF;
}

.hookinfo-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 1;
}

.tools-wrapper {
  margin-top: 8px;
  transition: opacity 0.2s linear 0s;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.hook-container.active ~ .tools-wrapper,
.scene-wrapper:hover .tools-wrapper{
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
}

.hook-voiceover-wrapper {
  width: 100%;
}

.hook-voiceover-container {
  min-height: 38px;
  display: flex;
  align-items: center;
  padding: 8px 10px;
  background: #FFFFFF;
  border-radius: 4px;
  cursor: pointer;
  color: #646A73;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; 
  & > .voiceover-name {
    flex: 1 1;
  }

  & > .icon-left {
    transform: rotate(180deg);
  }
}
</style>