<template>
  <div
    class="hook-container"
    :class="{ active }"
    @click="sceneClick(scene)"
  >
    <svg-icon
      name="hook_selected_triangle" 
      class="decoration" 
      :size="30"
    />
    <HookCover
      :key="mKey"
      :scene="scene"
      :node="materialNode"
      @click="materialClick"
    />
    <div class="hookinfo-container">
      <HookHeader
        :scene="scene"
        :canPlay="true"
      />
    </div>
  </div>
  <div
    class="tools-wrapper"
  >
    <HookTools
      :scene="scene"
      :index="index"
    />
  </div>
  <HookMaterialDialog
    v-model="materialVisble"
    :scene="scene"
    @confirm="handleReplaceHook"
  />
</template>

<script setup>
import { useMessage } from "@/utils";
import HookCover from "./hookcover.vue";
import HookTools from "./hooktools.vue";
import HookHeader from "./hookheader.vue";
import { parseNode, replaceAllVoice } from "../../utils/scene";
import { getUpdateSceneInfo } from "@/api/script";
import { useDraftStore } from "../../stores/draft";
import { useScriptStore } from "../../stores/script";
import HookMaterialDialog from "../dialog/hookmaterialdialog.vue";
import { validImport } from "@/pages/createVideos/utils/import";
import { useModalManager } from "@/components/common/custom-modal/instance";

const props = defineProps({
  scene: {
    type: Object,
    default: {},
  },
});

const {
  ratio,
  videoType,
  updateDraft,
} = useDraftStore();
const {
  scenes,
  creator,
  activeScene,
  loadingScene,
  defaultLanguage,
  defaultVoiceStyle,
  setDefaultVoice,
  updateDuration,
  setActiveScene,
  getTrack,
  refresh,
  seekTo,
  pause,
} = useScriptStore();

const message = useMessage();
const modalManager = useModalManager();
const materialNode = ref(null);
const materialVisble = ref(false);
const editorLoading = inject("editorLoading");
const active = computed(() =>
  props.scene === activeScene.value &&
  loadingScene.value !== props.scene
);
const mKey = computed(() => {
  let key = "";
  if (materialNode.value) {
    key = materialNode.value.id;
  }
  return key;
});

watch(
  () => props.scene.nodes,
  (newNodes) => {
    if (!props.scene.sceneId || !newNodes) return;
    const { primary } = parseNode(newNodes);
    if (primary) {
      materialNode.value = primary;
    }
  },
  {
    immediate: true,
  },
);

const sceneClick = (scene) => {
  setActiveScene(scene);
  seekTo(scene.start);
};

const materialClick = () => {
  pause();
  materialVisble.value = true;
  // collectData("boolvideo_scene_edit_click", {
  //   click: "replace_media",
  //   video_type: videoType.value,
  // });
  // track("boolvideo_scene_edit_click");
};

const replaceHook = (media) => {
  const { scene } = props;
  const newEnd = Math.floor(media.duration * 30);
  const diff = newEnd - scene.end;
  const defaultScenes = scenes.value.filter(
    item => item.type === "default" || !item.type
  );
  for (const defaultScene of defaultScenes) {
    updateDuration(defaultScene, diff);
  }
  // move bgm in timeline
  const bgmTrack = getTrack("audio");
  for (const node of bgmTrack.children) {
    node.conf.start += diff;
    node.conf.end += diff;
  }

  const { primary } = parseNode(scene.nodes);
  const newConf = {
    name: media.name,
    src: media.url,
    hdUrl: media.preview1080Url,
    coverPic: media.coverPic,
    materialMeta: {
      width480: media.width480,
      width1080: media.width1080,
      url480: media.preview480Url,
      url1080: media.preview1080Url,
    },
    end: newEnd
  };
  Object.assign(primary.conf, newConf);
};

const handleReplaceHook = async (media) => {
  pause();
  editorLoading.value = true;

  const newEnd = Math.floor(media.duration * 30);
  const params = {
    language: defaultLanguage.value,
    voiceName: media.voiceId,
    voiceStyle: defaultVoiceStyle.value,
  }
  replaceHook(media);
  replaceAllVoice(params)
    .then(() => {
      Object.assign(props.scene, {
        hookName: media.name,
        end: newEnd,
      });
      triggerRef(creator);
      nextTick(refresh);
      updateDraft();
      message.success("Processing completed");
    })
    .catch((e) => {
      console.error(e);
      message.error("Processing failed");
    })
    .finally(() => {
      editorLoading.value = false;
    });
};
</script>

<style lang="scss" scoped>
.hook-container {
  position: relative;
  width: 100%;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #F8F5FF;
  display: flex;
  gap: 12px;

  & > .decoration {
    position: absolute;
    left: -1px;
    top: -1px;
  }
}

.hook-container.active {
  border-color: #A378FF;
}

.hookinfo-container {
  flex: 1 1;
}

.tools-wrapper {
  margin-top: 8px;
  transition: opacity 0.2s linear 0s;
  transform: scale(0);
  opacity: 0;
  pointer-events: none;
}

.hook-container.active ~ .tools-wrapper,
.scene-wrapper:hover .tools-wrapper{
  transform: scale(1);
  opacity: 1;
  pointer-events: all;
}
</style>