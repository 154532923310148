<script setup>
import { getFilterList } from "@/api/resources";
import { useNetwork } from "@/composables";
import Material from "./material.vue";
import {
  useCreatorStore,
  useDraftStore,
  useHistoryStore,
  useDrag,
} from "../../stores";

const { addNode } = useCreatorStore();
const { updateDraft } = useDraftStore();
const { commit } = useHistoryStore();
const { assertNetworkError } = useNetwork();
const { dragData } = useDrag();

const files = ref([]);
const loading = ref(false);

onMounted(getFilters);

async function getFilters() {
  loading.value = true;
  const { success, data } = await getFilterList();

  if (success) {
    files.value = data;
  }
  loading.value = false;
}

async function addAsset(eventName, e, file, target) {
  assertNetworkError();
  const conf = {
    type: "filter",
    name: file.name,
    src: file.url,
    sourceId: file.id,
    duration: 90,
  };
  if (eventName === "mousemove") {
    Object.assign(dragData, {
      x: e.clientX,
      y: e.clientY,
      target,
      meta: conf,
    });
  } else {
    await addNode(conf, { shouldRefresh: true, shouldAnnotate: true });
    commit();
    updateDraft();
  }
}
</script>
<template>
  <Material title="Filters">
    <el-scrollbar>
      <div class="material-content">
        <el-skeleton animated :loading="loading">
          <template #template>
            <div v-for="(_, i) in Array(16)" class="skeleton-item" :key="i">
              <el-skeleton-item variant="rect" />
              <el-skeleton-item variant="text" />
            </div>
          </template>
          <div class="file-list">
            <div class="file-item" v-for="(file, i) in files" :key="i">
              <div class="image-wrapper">
                <img
                  loading="lazy"
                  draggable="false"
                  :src="file.coverPic"
                  v-drag:[file]="addAsset"
                />
              </div>
              <div class="title">{{ file.name }}</div>
            </div>
          </div>
        </el-skeleton>
      </div>
    </el-scrollbar>
  </Material>
</template>
<style scoped>
.material-content {
  margin-top: 22px;
}
.file-list,
:deep(.el-skeleton) {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
}
.skeleton-item {
  display: flex;
  flex-wrap: wrap;
}
.image-wrapper,
:deep(.el-skeleton__rect) {
  width: 100%;
  height: 70px;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  border-radius: 4px;
  background: #ffffff;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 200ms;
  overflow: hidden;
  cursor: pointer;
}
:deep(.el-skeleton__text) {
  margin: 4px 0;
  height: 12px;
}
.image-wrapper:hover {
  border-width: 2px;
  border-color: #875eff;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.title {
  padding: 0 2px;
  text-align: center;
  font-size: 12px;
  line-height: 20px;
  color: #646a73;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
</style>
